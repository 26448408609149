import VueRouter from "vue-router";
import routes from "./routes";

//Firebase config
import { db, auth } from "@/firebase/config";

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (router.app.$root.isOffline) {
    router.app.$h.toast(
      "error",
      "Parece que no tienes internet, tus cambios se sincronizarán cuando te conectes nuevamente",
      "top"
    );
  }

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = !!auth.currentUser;

    switch (to.name) {
      case "login":
        setTimeout(() => {
          router.app.$root.loading = false;
        });
        //
        break;
      default:
        setTimeout(() => {
          router.app.$root.loading = false;
        });
    }

    if (requiresAuth && !isAuthenticated) {
      next("/login");
    } else if (!requiresAuth && isAuthenticated) {
      next({ name: "Dashboard" });
    } else if (to.name === from.name) {
      next(false);
    } else {
      next();
    }
});

export default router;
