var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"pos intro-y grid grid-cols-1 gap-5"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var handleSubmit = ref.handleSubmit;
var validate = ref.validate;
return [_c('form',{ref:"registerForm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"grid grid-cols-12 gap-6"},[_c('div',{staticClass:"intro-y col-span-12 lg:col-span-12"},[_c('div',{staticClass:"intro-y box mt-5"},[_c('div',{staticClass:"flex flex-col sm:flex-row items-center"},[_c('h2',{staticClass:"font-medium text-base"},[_vm._v("Fecha y hora")])]),_c('div',{staticClass:"intro-y px-5 mx-auto text-center"},[_c('div',{staticClass:"relative mx-auto text-center text-gray-700 dark:text-gray-300"},[_c('div',{staticClass:"intro-y mx-auto text-center nl items-center"},[_c('div',{staticClass:"row flex items-center mx-auto text-center"},[_c('vc-date-picker',{staticClass:"flex-grow col-4 mx-auto dateCenter",attrs:{"placement":"bottom","popover-align":"center","locale":'es',"title-position":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var togglePopover = ref.togglePopover;
return [_c('v-text-field',{staticClass:"dateCenter mt-1",attrs:{"mode":"dateTime","dense":"","outlined":"","background-color":"#ffffff","readonly":"","value":inputValue,"hide-details":"auto"},on:{"focus":function($event){return togglePopover({ placement: 'auto-start' })}}})]}}],null,true),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-spacer'),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Hora","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1)],1)]),_c('v-divider')],1)]),_c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 mt-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v(" Datos del cliente ")])]),_c('div',{staticClass:"intro-y items-center grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 px-5 gap-5"},[_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","label":"Nombre completo *","hide-details":"auto"},on:{"blur":_vm.splitFullName},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})]}}],null,true)})],1),_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Teléfono","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Teléfono","hide-details":"auto"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1),_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Zona","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{staticStyle:{"z-index":"9999"},attrs:{"error-messages":errors,"hide-details":"auto","auto-select-first":"","label":"Zona *","items":_vm.zones,"item-text":"name","item-value":"code","no-data-text":"Sin resultados"},model:{value:(_vm.zone),callback:function ($$v) {_vm.zone=$$v},expression:"zone"}})]}}],null,true)})],1),_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Notas","rules":"min:3|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{staticClass:"mt-7",attrs:{"error-messages":errors,"label":"Dirección","auto-grow":"","rows":"1"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,true)})],1),_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"min:4|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email","hide-details":"auto"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Contacto Adicional","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Contacto Adicional","hide-details":"auto"},model:{value:(_vm.additionalContact),callback:function ($$v) {_vm.additionalContact=$$v},expression:"additionalContact"}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 mt-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v(" Detalles de la fosa séptica ")])]),_c('div',{staticClass:"intro-y items-center px-5 gap-5"},[_c('v-divider'),_c('div',{staticClass:"items-center grid grid-cols-3"},[_c('v-switch',{attrs:{"label":"Calcular Metros Cúbicos"},model:{value:(_vm.calculateM3),callback:function ($$v) {_vm.calculateM3=$$v},expression:"calculateM3"}}),_c('v-switch',{attrs:{"label":"Aplicar IVA"},model:{value:(_vm.applyIva),callback:function ($$v) {_vm.applyIva=$$v},expression:"applyIva"}}),(!_vm.calculateM3 || _vm.tankType === 'unknown')?_c('div',[_c('div',{staticClass:"items-center mt-5"},[_c('ValidationProvider',{attrs:{"name":"Metros Cúbicos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"clearable":"","error-messages":errors,"required":"","label":"Metros cúbicos *","hide-details":"auto"},model:{value:(_vm.rM3),callback:function ($$v) {_vm.rM3=$$v},expression:"rM3"}})]}}],null,true)})],1)]):_vm._e()],1),_c('v-divider'),_c('div',{staticClass:"items-center mt-5"},[_c('ValidationProvider',{attrs:{"name":"Fosa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{staticStyle:{"z-index":"9999"},attrs:{"error-messages":errors,"hide-details":"auto","auto-select-first":"","label":"Tipo de fosa *","items":_vm.tankTypes,"item-text":"name","item-value":"code","no-data-text":"Sin resultados"},model:{value:(_vm.tankType),callback:function ($$v) {_vm.tankType=$$v},expression:"tankType"}})]}}],null,true)})],1),(_vm.calculateM3)?_c('div',[(_vm.tankType === 'tank')?_c('div',{staticClass:"grid grid-cols-3 gap-3 mt-5"},[_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Alto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","clearable":"","error-messages":errors,"required":"","label":"Alto *","hide-details":"auto"},model:{value:(_vm.rHeight),callback:function ($$v) {_vm.rHeight=$$v},expression:"rHeight"}})]}}],null,true)})],1),_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Ancho","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","clearable":"","error-messages":errors,"required":"","label":"Ancho *","hide-details":"auto"},model:{value:(_vm.rWidth),callback:function ($$v) {_vm.rWidth=$$v},expression:"rWidth"}})]}}],null,true)})],1),_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Largo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","clearable":"","error-messages":errors,"required":"","label":"Largo *","hide-details":"auto"},model:{value:(_vm.rLength),callback:function ($$v) {_vm.rLength=$$v},expression:"rLength"}})]}}],null,true)})],1)]):_vm._e(),(_vm.tankType === 'tank')?_c('div',{staticClass:"items-center mt-5 text-center"},[_c('h2',{staticClass:"text-lg font-bold"},[_vm._v("Métros Cúbicos: "+_vm._s(_vm.tankM3))])]):_vm._e(),(_vm.tankType === 'sewer')?_c('div',{staticClass:"grid grid-cols-2 gap-3 mt-5"},[_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Alto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","clearable":"","error-messages":errors,"required":"","label":"Alto *","hide-details":"auto"},model:{value:(_vm.rHeight),callback:function ($$v) {_vm.rHeight=$$v},expression:"rHeight"}})]}}],null,true)})],1),_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Radio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","clearable":"","error-messages":errors,"required":"","label":"Radio *","hide-details":"auto"},model:{value:(_vm.rRadius),callback:function ($$v) {_vm.rRadius=$$v},expression:"rRadius"}})]}}],null,true)})],1)]):_vm._e(),(_vm.tankType === 'sewer')?_c('div',{staticClass:"items-center mt-5 text-center"},[_c('h2',{staticClass:"text-lg font-bold"},[_vm._v("Métros Cúbicos: "+_vm._s(_vm.sewerM3))])]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 mt-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v(" Precio ")])]),_c('div',{staticClass:"intro-y items-center px-5 gap-5"},[_c('v-divider'),_c('div',{staticClass:"items-center my-5 text-center grid grid-cols-2 gap-4"},[_c('v-btn',{attrs:{"color":"dark","dark":"","pill":""}},[_vm._v("Mínimo: "+_vm._s(_vm._f("currency")(_vm.baseCost * 2)))]),_c('v-btn',{attrs:{"color":"dark","dark":"","pill":""}},[_vm._v("Metro adicional: "+_vm._s(_vm._f("currency")(_vm.extraM3)))])],1),_c('div',{staticClass:"items-center my-5 text-center grid grid-cols-1"},[_c('v-btn',{staticClass:"py-5",attrs:{"color":"info","small":"","dark":""},on:{"click":_vm.applySuggestedPrice}},[_c('h4',{staticClass:"text-xl"},[_vm._v("Sugerido: "),_c('b',[_vm._v(_vm._s(_vm._f("currency")(_vm.estimatedCost)))])])])],1),_c('div',{staticClass:"items-center my-5 grid grid-cols-2 gap-4"},[_c('ValidationProvider',{attrs:{"name":"Precio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"clearable":"","type":"number","error-messages":errors,"required":"","label":"Precio *","hide-details":"auto"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Descuento","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"clearable":"","type":"number","error-messages":errors,"label":"Descuento","hide-details":"auto"},model:{value:(_vm.priceDiscount),callback:function ($$v) {_vm.priceDiscount=$$v},expression:"priceDiscount"}})]}}],null,true)})],1),_c('div',{staticClass:"items-center my-5 text-center grid grid-cols-1"},[_c('v-btn',{staticClass:"py-5",attrs:{"color":"success","small":"","dark":""}},[_c('h4',{staticClass:"text-xl"},[_vm._v("Total: "),_c('b',[_vm._v(_vm._s(_vm._f("currency")(_vm.priceTotal)))])])])],1),_c('v-divider')],1),_c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 mt-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v(" Recordatorio ")])]),_c('div',{staticClass:"intro-y items-center grid md:grid-cols-1 sm:grid-cols-1 grid-cols-1 px-5 gap-5"},[_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Period","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{staticStyle:{"z-index":"9999"},attrs:{"error-messages":errors,"hide-details":"auto","auto-select-first":"","label":"Periodo","items":_vm.periods,"no-data-text":"Sin resultados"},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v(" Notas o detalles internos ")])]),_c('div',{staticClass:"grid md:grid-cols-1 sm:grid-cols-1 grid-cols-1 intro-y items-center px-5 pb-5 mb-5 mt-5 gap-5"},[_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Notas","rules":"min:3|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Notas internas","auto-grow":"","rows":"1"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"disabled":invalid,"type":"submit","color":"info"}},[_vm._v(" Registrar Trabajo ")])],1)])])])])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro-y flex flex-col sm:flex-row items-center mt-8"},[_c('h2',{staticClass:"text-lg font-medium mr-auto"},[_vm._v("Registrar huéspedes")])])}]

export { render, staticRenderFns }