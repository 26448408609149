<template>
  <div class="grid grid-cols-1">
    <v-data-table
      v-if="!isLoading"
      disable-pagination
      :item-class="tableRowClassName"
      :items="tableData"
      :headers="tableHeaders"
      :hide-default-footer="true"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="uid"
      show-expand
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de trabajo</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.id="{ item }">
        <v-tooltip
          v-if="!!item.notes || !!item.inNotes || item.guestLoyaltyLevel > 0"
          internal-activator
          color="#222222"
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red"
              class="cursor-pointer animate"
              v-bind="attrs"
              v-on="on"
              >mdi-access-point</v-icon
            >
            -
          </template>
          <span class="m-2"></span>
          <div v-show="!!item.notes">
            <h4 class="text-theme-warning font-weight-bold">
              Notas del cliente
            </h4>
            <h5 class="text-white">{{ item.notes }}</h5>
            <span class="m-2"></span>
          </div>
          <div v-show="item.guestLoyaltyLevel > 0">
            <v-chip class="ma-2" color="green" text-color="white">
              {{ loyaltyLevel(item.guestLoyaltyLevel) }}
            </v-chip>
          </div>
        </v-tooltip>
        <v-tooltip
            internal-activator
            color="#222222"
            top
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="cursor-pointer">
              {{item.id | truncate(10)}}
            </span>
          </template>
          <span>{{item.id}}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.phone="{ item }">
        {{ !!item.phone ? item.phone.slice(0, 4) + '-' + item.phone.slice(4) : '' }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ formattedDate(item.date) }}
      </template>
      <template v-slot:item.zone="{ item }">
        {{ decodeZone(item.zone) }}
      </template>
      <template v-slot:item.price="{ item }">
        {{ item.price | currency }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                :disabled="item.jobDone"
              color="green"
              x-small
              v-bind="attrs"
              class="ma-1 white--text"
              fab
              @click="doJobDone(item.id)"
              v-on="on"
            >
              <v-icon dark>
                mdi-chevron-up
              </v-icon>
            </v-btn>
          </template>
          <span>Completar trabajo</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue"
              x-small
              v-bind="attrs"
              class="ma-1 white--text"
              fab
              @click="goToEdit(item)"
              v-on="on"
            >
              <v-icon dark>
                mdi-book-edit
              </v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red"
              x-small
              v-bind="attrs"
              class="ma-1 white--text"
              fab
              @click="doCancel(item.id, item.email)"
              v-on="on"
            >
              <v-icon dark>
                mdi-cancel
              </v-icon>
            </v-btn>
          </template>
          <span>Cancelar</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
            :id="'print-' + item.reservationNumber"
            :ref="'print-' + item.reservationNumber"
            class="d-print-table"
            :colspan="headers.length"
        >
          <div class="p-3">
            <div class="row ml-aut mr-auto">

              <p class="col-12 col-md-3 text-muted mb-1" @click="doCopy(item.fullName)">Nombre completo<br />
                <b>{{ item.fullName }}</b>
                <v-divider class="mb-1 mt-2"></v-divider>
              </p>
              <p class="text-muted col-6 col-md-3 d-print-block" @click="doCopy(decodeZone(item.zone))">
                Zona <br />
                <b>{{ decodeZone(item.zone) || "N/A" }}</b>
                <v-divider class="mb-1 mt-2"></v-divider>
              </p>
              <p
                  class="text-muted col-6 col-md-3 d-print-block"
                  @click="doCopy(formattedDate(item.date))"
              >
                Fecha <br />
                <b>{{ formattedDate(item.date) }}</b>
                <v-divider class="mb-1 mt-2"></v-divider>
              </p>
              <p
                  class="text-muted col-6 col-md-3 d-print-block"
                  @click="doCopy(item.time)"
              >
                Hora <br />
                <b>{{ item.time }}</b>
                <v-divider class="mb-1 mt-2"></v-divider>
              </p>
              <p
                  class="text-muted col-6 col-md-3 d-print-block"
                  @click="doCopy(item.phone)"
              >
                Teléfono <br />
                <b>{{ item.phone || "N/A" }}</b>
                <v-divider class="mb-1 mt-2"></v-divider>
              </p>
              <p
                  class="text-muted col-6 col-md-3 d-print-block"
                  @click="doCopy(item.notes)"
              >
                Notas <br />
                <b>{{ item.notes || "N/A" }}</b>
                <v-divider class="mb-1 mt-2"></v-divider>
              </p>
              <p
                  class="text-muted col-6 col-md-3 d-print-block d-print-none"
                  @click="doCopy(item.inNotes)"
              >
                Notas Internas: <br />
                <b>{{ item.inNotes || "N/A" }}</b>
                <v-divider class="mb-1 mt-2"></v-divider>
              </p>
              <p
                  class="text-muted col-6 col-md-3 d-print-block d-print-none"
                  @click="doCopy(item.price)"
              >
                Precio: <br />
                <b>{{ item.price | currency }}</b>
                <v-divider class="mb-1 mt-2"></v-divider>
              </p>
              <p
                  class="text-muted col-12 col-md-12 d-print-block d-print-none"
                  @click="doCopy(item.address)"
              >
                Dirección: <br />
                <b>{{ item.address || "N/A" }}</b>
                <v-divider class="mb-1 mt-2"></v-divider>
              </p>
            </div>
            <v-divider></v-divider>
            <div class="col-12 grid grid-colums-1">
              <v-btn
                  class="button button--lg w-full ml-auto mr-auto xl:w-32 text-white bg-theme-0 align-top d-print-none"
                  rounded
                  icon
                  type="info"
                  @click="printSection('print-' + item.reservationNumber)"
              >
                <i class="fa fa-print text-lg text-white"></i>
              </v-btn>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>
    <div v-else>
      <v-skeleton-loader
        type="table: table-heading, table-thead, table-tbody"
      ></v-skeleton-loader>
    </div>
  </div>
</template>
<script>
import moment from "moment";
// import swal from 'sweetalert2';
import XLSX from "xlsx";
import Swal from "sweetalert2";
import { db, auth } from "@/firebase/config";
import VueQrcode from "vue-qrcode";
export default {
  components: {
    VueQrcode
  },
  props: {
    selectedDate: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      zones: [],
      isLoading: false,
      tableData: [{}],
      isNotPrinting: true,
      expanded: [],
      tableHeaders: [
        {
          text: "# Orden",
          align: "start",
          sortable: true,
          value: "id"
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "fullName"
        },
        {
          text: "Teléfono",
          align: "start",
          sortable: true,
          value: "phone"
        },
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "date"
        },
        {
          text: "Hora",
          align: "start",
          sortable: true,
          value: "time"
        },
        {
          text: "Zona",
          align: "center",
          sortable: true,
          value: "zone"
        },

        {
          text: "Precio",
          align: "center",
          sortable: true,
          value: "price"
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions"
        }
      ],
    };
  },
  computed: {
    storeSelectedDate() {
      return this.$store.state.main.selectedDate;
    }
  },
  watch: {
    storeSelectedDate(val) {
      this.loadJobs();
    },
    tableData: function(val) {
      val.length
        ? (this.$root.dateHasCheckins = true)
        : (this.$root.dateHasCheckins = false);
    }
  },
  created() {
    moment().utcOffset("-06:00");
  },
  mounted() {
    auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
        this.loadZones().then(()=>{
          this.loadJobs();
        })
      }
    });
  },

  beforeDestroy() {
    if (this.$root.snapshots.jobs) {
      this.$root.snapshots.jobs();
    }
    if (this.$root.snapshots.zones) {
      this.$root.snapshots.zones();
    }
  },
  methods: {
    tableRowClassName(row) {
      if (row.jobDone) {
        return "danger-row";
      }
      // else {
      //   return 'warning-row';
      // }
      return "";
    },
    decodeZone(code) {
      let index = this.zones.findIndex(x => x.code === code);
      // console.log(index)
      if (index >= 0){
        return this.zones[index].name.toString()
      }
    },
    printSection(id) {
      this.$htmlToPaper(id);
    },
    loyaltyLevel(level) {
      switch (level) {
        case 1:
          return "Cliente Recurrente";
        case 2:
          return "Cliente Frecuente";
        case 3:
          return "Cliente Premium";
        default:
          return "Nuevo cliente";
      }
    },
    async doCancel(id, email) {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Motivo",
        inputPlaceholder: "Motivo o detalles de la cancelación",
        inputAttributes: {
          "aria-label": "Escribe aquí"
        },
        showCancelButton: true
      });
      if (text) {
        this.$swal
          .fire({
            title: "Estas segur@?",
            text: `Estás a punto de cancelar este trabajo, deseas continuar?`,
            icon: "question",
            showCancelButton: true,
            customClass: {
              confirmButton:
                "button m-2 button--sm text-white bg-theme-1 align-top",
              cancelButton:
                "button m-2 button--sm text-white bg-theme-0 align-top"
            },
            confirmButtonText: "Si, estoy seguro",
            buttonsStyling: true
          })
          .then(result => {
            if (result.value) {
              try {
                db.collection('jobs')
                  .doc(id)
                  .update({
                    cancelled: true,
                    cancelNote: text
                  })
                this.$h.toast("success", "Se canceló la reserva", "bottom");
              } catch (e) {
                this.$h.showError(null, e);
              }
            }
          });
      }
      else {
        this.$swal({
          title: "Oups!",
          text: "Es necesario especificar el motivo de la cancelación",
          icon: "error",
          confirmButtonText: "Entiendo"
        });
      }
    },
    goToEdit(row) {
      this.$router.push({ name: "EditJobs", params: { row: row } });
    },
    /*
     =========================================================
     * Job Done
     =========================================================
    */
    async doJobDone(id) {
      this.$swal
          .fire({
            title: "Estas seguro?",
            text: `Esto marcará el trabajo como completado`,
            icon: "question",
            showCancelButton: true,
            customClass: {
              confirmButton:
                  "button m-2 button--sm text-white bg-theme-1 align-top",
              cancelButton:
                  "button m-2 button--sm text-white bg-theme-0 align-top"
            },
            confirmButtonText: "Si, estoy seguro",
            buttonsStyling: true
          })
          .then(result => {
            if (result.value) {
              try {
                db.collection('jobs')
                    .doc(id)
                    .update({
                      jobDone: true,
                    });
                this.$h.toast(
                    "success",
                    "Trabajo completado correctamente",
                    "bottom"
                );
              } catch (e) {
                this.$h.showError(null, e);
              }
            }
          });
    },
    diffDate(date1, date2) {
      if (!!date1 && !!date2) {
        const diffTime = Math.abs(date2 - date1);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }
    },
    formattedDate(date) {
      if (date) {
        return moment(date.seconds * 1000).format("DD/MM/YYYY");
      }
    },
    exportXLS() {
      let data = XLSX.utils.json_to_sheet(this.tableData);
      const workbook = XLSX.utils.book_new();
      const filename = "today-checkins";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    doCopy: function(text) {
      let self = this;
      this.$copyText(text).then(
        function(res) {
          self.$h.toast(
            "info",
            "Se ha copiado al portapapeles",
            "bottom",
            900,
            true,
            true
          );
        },
        function(e) {
          alert("Can not copy");
        }
      );
    },
    async loadZones() {
      try {
        this.$root.snapshots.zones = await db
          .collection("zones")
          .onSnapshot(docSnapshot => {
            docSnapshot.forEach(doc => {
              let job = doc.data()
              job.id = doc.id
              this.zones.push(job)
            })
          });
      } catch (e) {
        console.log(e);
      }
    },
    async loadJobs() {
      let self = this;
      let selectedDate = this.$store.state.main.selectedDate;
      let now = moment(selectedDate);
      this.$root.snapshots.jobs = await db
        .collection('jobs')
        .where("date", "==", selectedDate)
        .orderBy("price")
        .onSnapshot(docSnapshot => {
          this.isLoading = true;
          self.tableData.splice(0, self.tableData.length);
          self.tableData.length = 0;
          this.$root.dataBus.totalCheckins = 0;
          // self.tableData = [];
          docSnapshot.forEach(doc => {
            let job = doc.data();
            job.id = doc.id;
            self.tableData.push(job);
          });
          this.isLoading = false;
        });
    }
  }
};
</script>
<style></style>
