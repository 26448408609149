<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Registrar huéspedes</h2>
    </div>
    <div class="pos intro-y grid grid-cols-1 gap-5">
      <!-- BEGIN: Ticket -->
      <ValidationObserver v-slot="{ invalid, validated, handleSubmit, validate  }">
        <form ref="registerForm" @submit.prevent="handleSubmit(submit)">
          <div class="grid grid-cols-12 gap-6">
            <!-- Formulario Principal -->
            <div class="intro-y col-span-12 lg:col-span-12">
              <div class="intro-y box mt-5">
                <!-- Titulo -->
                <div class="flex flex-col sm:flex-row items-center">
                  <h2 class="font-medium text-base">Fecha y hora</h2>
                </div>
                <!-- Datepicker -->
                <div class="intro-y px-5 mx-auto text-center">
                  <div class="relative mx-auto text-center text-gray-700 dark:text-gray-300">
                    <div class="intro-y mx-auto text-center nl items-center">
                      <div class="row flex items-center mx-auto text-center">
                        <vc-date-picker
                            placement="bottom"
                            popover-align="center"
                            v-model="date"
                            :locale="'es'"
                            class="flex-grow col-4 mx-auto dateCenter"
                            title-position="right"
                        >
                          <template v-slot="{ inputValue, togglePopover }">
                            <v-text-field
                                mode="dateTime"
                                dense
                                outlined
                                background-color="#ffffff"
                                readonly
                                class="dateCenter mt-1"
                                :value="inputValue"
                                hide-details="auto"
                                @focus="togglePopover({ placement: 'auto-start' })"
                            ></v-text-field>
                          </template>
                        </vc-date-picker>
                        <v-spacer></v-spacer>
                        <v-menu
                            ref="menu"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                clearable
                                v-model="time"
                                label="Hora"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                              v-if="menu2"
                              v-model="time"
                              full-width
                              @click:minute="$refs.menu.save(time)"
                          ></v-time-picker>
                        </v-menu>
                      </div>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </div>
                <!-- Segunda Sección -->
                <div class="flex flex-col sm:flex-row items-center p-5 mt-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 class="font-medium text-base mr-auto">
                    Datos del cliente
                  </h2>
                </div>
                <!-- Inputs Segunda Sección -->
                <div class="intro-y items-center grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 px-5 gap-5">
                  <!-- Nombre -->
                  <div class="items-center">
                    <ValidationProvider v-slot="{ errors, valid }" name="Nombre" rules="required|min:3">
                      <v-text-field
                          :error-messages="errors"
                          v-model="fullName"
                          @blur="splitFullName"
                          required
                          label="Nombre completo *"
                          hide-details="auto"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <!-- Teléfono -->
                  <div class="items-center">
                    <ValidationProvider v-slot="{ errors, valid }" name="Teléfono" rules="min:4">
                      <v-text-field
                          :error-messages="errors"
                          v-model="phone"
                          label="Teléfono"
                          hide-details="auto"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <!-- Zona -->
                  <div class="items-center">
                    <ValidationProvider v-slot="{ errors, valid }" name="Zona" rules="required">
                      <v-autocomplete
                          style="z-index: 9999"
                          :error-messages="errors"
                          hide-details="auto"
                          auto-select-first
                          label="Zona *"
                          :items="zones"
                          item-text="name"
                          item-value="code"
                          v-model="zone"
                          no-data-text="Sin resultados">
                      </v-autocomplete>
                    </ValidationProvider>
                  </div>
                  <!-- Dirección -->
                  <div class="items-center">
                    <ValidationProvider v-slot="{ errors, valid }" name="Notas" rules="min:3|max:200">
                      <v-textarea
                          class="mt-7"
                          v-model="address"
                          :error-messages="errors"
                          label="Dirección"
                          auto-grow
                          rows="1"
                      ></v-textarea>
                    </ValidationProvider>
                  </div>
                  <!-- Email -->
                  <div class="items-center">
                    <ValidationProvider v-slot="{ errors, valid }" name="Email" rules="min:4|email">
                      <v-text-field
                          :error-messages="errors"
                          v-model="email"
                          label="Email"
                          hide-details="auto"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <!-- Nombre Adicional -->
                  <div class="items-center">
                    <ValidationProvider v-slot="{ errors, valid }" name="Contacto Adicional" rules="min:3">
                      <v-text-field
                          :error-messages="errors"
                          v-model="additionalContact"
                          label="Contacto Adicional"
                          hide-details="auto"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- Fosa -->
                <div class="flex flex-col sm:flex-row items-center p-5 mt-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 class="font-medium text-base mr-auto">
                    Detalles de la fosa séptica
                  </h2>
                </div>
                <!-- Inputs Segunda Sección -->
                <div class="intro-y items-center px-5 gap-5">
                  <v-divider></v-divider>
                  <div class="items-center grid grid-cols-3">
                    <v-switch label="Calcular Metros Cúbicos" v-model="calculateM3"></v-switch>
                    <v-switch label="Aplicar IVA" v-model="applyIva"></v-switch>
                    <div v-if="!calculateM3 || tankType === 'unknown'">
                      <div class="items-center mt-5">
                        <ValidationProvider v-slot="{ errors, valid }" name="Metros Cúbicos" rules="required">
                          <v-text-field
                              clearable
                              :error-messages="errors"
                              v-model="rM3"
                              required
                              label="Metros cúbicos *"
                              hide-details="auto"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <!-- Tipos de fosa -->
                  <div class="items-center mt-5">
                    <ValidationProvider v-slot="{ errors, valid }" name="Fosa" rules="required">
                      <v-select
                          style="z-index: 9999"
                          :error-messages="errors"
                          hide-details="auto"
                          auto-select-first
                          label="Tipo de fosa *"
                          :items="tankTypes"
                          item-text="name"
                          item-value="code"
                          v-model="tankType"
                          no-data-text="Sin resultados">
                      </v-select>
                    </ValidationProvider>
                  </div>
                  <div v-if="calculateM3">
                    <div v-if="tankType === 'tank'" class="grid grid-cols-3 gap-3 mt-5">
                      <div class="items-center">
                        <ValidationProvider v-slot="{ errors, valid }" name="Alto" rules="required">
                          <v-text-field type="number" clearable
                              :error-messages="errors"
                              v-model="rHeight"
                              required
                              label="Alto *"
                              hide-details="auto"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                      <div class="items-center">
                        <ValidationProvider v-slot="{ errors, valid }" name="Ancho" rules="required">
                          <v-text-field type="number" clearable
                              :error-messages="errors"
                              v-model="rWidth"
                              required
                              label="Ancho *"
                              hide-details="auto"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                      <div class="items-center">
                        <ValidationProvider v-slot="{ errors, valid }" name="Largo" rules="required">
                          <v-text-field type="number" clearable
                              :error-messages="errors"
                              v-model="rLength"
                              required
                              label="Largo *"
                              hide-details="auto"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div v-if="tankType === 'tank'" class="items-center mt-5 text-center">
                      <h2 class="text-lg font-bold">Métros Cúbicos: {{tankM3}}</h2>
                    </div>
                    <div v-if="tankType === 'sewer'" class="grid grid-cols-2 gap-3 mt-5">
                      <div class="items-center">
                        <ValidationProvider v-slot="{ errors, valid }" name="Alto" rules="required">
                          <v-text-field type="number" clearable
                                        :error-messages="errors"
                                        v-model="rHeight"
                                        required
                                        label="Alto *"
                                        hide-details="auto"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                      <div class="items-center">
                        <ValidationProvider v-slot="{ errors, valid }" name="Radio" rules="required">
                          <v-text-field type="number" clearable
                                        :error-messages="errors"
                                        v-model="rRadius"
                                        required
                                        label="Radio *"
                                        hide-details="auto"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div v-if="tankType === 'sewer'" class="items-center mt-5 text-center">
                      <h2 class="text-lg font-bold">Métros Cúbicos: {{sewerM3}}</h2>
                    </div>
                  </div>
                </div>
                <!-- Precio -->
                <div class="flex flex-col sm:flex-row items-center p-5 mt-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 class="font-medium text-base mr-auto">
                    Precio
                  </h2>
                </div>
                <!-- Inputs Segunda Sección -->
                <div class="intro-y items-center px-5 gap-5">
                  <v-divider></v-divider>
                  <div class="items-center my-5 text-center grid grid-cols-2 gap-4">
                    <v-btn color="dark" dark pill>Mínimo: {{baseCost * 2 | currency}}</v-btn>
                    <v-btn color="dark" dark pill>Metro adicional: {{extraM3 | currency}}</v-btn>
                  </div>
                  <div class="items-center my-5 text-center grid grid-cols-1">
                    <v-btn class="py-5" @click="applySuggestedPrice" color="info" small dark><h4 class="text-xl">Sugerido: <b>{{estimatedCost | currency}}</b></h4></v-btn>
                  </div>
                  <div class="items-center my-5 grid grid-cols-2 gap-4">
                    <ValidationProvider v-slot="{ errors, valid }" name="Precio" rules="required">
                      <v-text-field
                          clearable
                          type="number"
                          :error-messages="errors"
                          v-model="price"
                          required
                          label="Precio *"
                          hide-details="auto"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors, valid }" name="Descuento" rules="">
                      <v-text-field
                          clearable
                          type="number"
                          :error-messages="errors"
                          v-model="priceDiscount"
                          label="Descuento"
                          hide-details="auto"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="items-center my-5 text-center grid grid-cols-1">
                    <v-btn class="py-5" color="success" small dark> <h4 class="text-xl">Total: <b>{{priceTotal | currency}}</b></h4></v-btn>
                  </div>
                  <v-divider></v-divider>
                </div>
                <!-- Segunda Sección -->
                <div class="flex flex-col sm:flex-row items-center p-5 mt-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 class="font-medium text-base mr-auto">
                    Recordatorio
                  </h2>
                </div>
                <!-- Inputs Segunda Sección -->
                <div class="intro-y items-center grid md:grid-cols-1 sm:grid-cols-1 grid-cols-1 px-5 gap-5">
                  <!-- Periodo -->
                  <div class="items-center">
                    <ValidationProvider v-slot="{ errors, valid }" name="Period" rules="">
                      <v-autocomplete
                          style="z-index: 9999"
                          :error-messages="errors"
                          hide-details="auto"
                          auto-select-first
                          label="Periodo"
                          :items="periods"
                          v-model="period"
                          no-data-text="Sin resultados">
                      </v-autocomplete>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- Sexta Sección -->
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 class="font-medium text-base mr-auto">
                    Notas o detalles internos
                  </h2>
                </div>
                <div class="grid md:grid-cols-1 sm:grid-cols-1 grid-cols-1 intro-y items-center px-5 pb-5 mb-5 mt-5 gap-5">
                  <!-- Notas -->
                  <div class="items-center">
                    <ValidationProvider v-slot="{ errors, valid }" name="Notas" rules="min:3|max:200">
                      <v-textarea
                          v-model="notes"
                          :error-messages="errors"
                          label="Notas internas"
                          auto-grow
                          rows="1"
                      ></v-textarea>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- Submit -->
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                  <v-btn :disabled="invalid" type="submit" class="mx-auto" color="info">
                    Registrar Trabajo
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <!-- END: Ticket -->
    </div>
  </div>
</template>
<script>
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
import { db, auth, adminDB, adminAuth } from "@/firebase/config";

//Others
import countries from "@/JSON/countryLargeData.json";
import spanishSpeakers from "@/JSON/spanishSpeakenCountries.json";

import { extend } from "vee-validate";
import {
  alpha_spaces,
  required,
  email,
  confirmed,
  numeric,
  min_value,
  min,
  max,
  max_value
} from "vee-validate/dist/rules";
import VueQrcode from "vue-qrcode";
import Tooltip from "@/views/Tooltip";

extend("confirmed", confirmed);
extend("numeric", numeric);
extend("min", min);
extend("min_value", min_value);
extend("max", max);
extend("max_value", max_value);

extend('required', {
  ...required,
  message: 'Este campo es obligatorio',
});
extend('email', {
  ...email,
  message: 'No es un email válido',
});
extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'Solo se permiten letras y espacios',
});

export default {
  components: {
    Tooltip,
    VueQrcode
  },
  data() {
    return {
      additionalContact: null,
      menu2: false,
      baseCost: 17500,
      extraM3: 15000,
      address: '',
      zones: [],
      periods: ['Semanal', 'Quincenal', 'Mensual', 'Bimestral', 'Trimestral', 'Semestral', 'Nueve Meses', 'Anual'],
      tankTypes: [
        {name: 'Tanque', code: 'tank'},
        {name: 'Alcantarilla / Cilíndrica', code: 'sewer'},
        {name: 'Cliente no sabe', code: 'unknown'}
      ],
      period: 'Anual',
      calculateM3: false,
      rM3: 0,
      rLength: 0,
      rHeight: 0,
      rWidth: 0,
      rRadius: 0,
      tankType: "tank",
      price: null,
      priceDiscount: null,
      applyIva: false,
      iva: null,
      totalPrice: null,
      zone: 'snrmn01',
      rules: [
        value => !!value || "Required.",
        value => (value && value.length >= 3) || "Min 3 characters"
      ],
      emailChanged: false,
      fullName: "",
      enterKey: new KeyboardEvent("keydown", {
        bubbles: true,
        cancelable: true,
        keyCode: 13
      }),
      carPlate: "",
      foodPlanSelected: "Desayunos",
      date: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      time: null,
      guestLoyaltyLevel: 0,
      reservationNumber: "",
      country: "",
      phone: "",
      notes: "",
      firstName: "",
      lastName: "",
      email: "",
      selects: {
        documentTypes: [
          {
            value: "dni",
            label: "ID"
          },
          {
            value: "passport",
            label: "Passport"
          },
          {
            value: "dimex",
            label: "Dimex"
          },
          {
            value: "driver_license",
            label: "Driver License"
          }
        ],
        foodPlan: [
          "Desayunos",
          "Desayunos y Almuerzo",
          "Pensión completa",
          "N/A"
        ],
        paxList: [0, 1, 2, 3, 4, 5, 6],
        paxListMin: [1, 2, 3, 4, 5, 6],
        paxListMinZero: [0, 1, 2, 3, 4, 5, 6]
      },
    };
  },
  computed: {
    tankM3(){
      if (this.calculateM3){
        let h = this.rHeight;
        let w = this.rWidth;
        let l = this.rLength;
        return h * w * l;
      } else{
        return this.rM3;
      }
    },
    sewerM3(){
      if (this.calculateM3){
        let h = this.rHeight;
        let r = this.rRadius;
        return Math.round(Math.PI * h * Math.pow(r, 2))
      } else{
        return this.rM3;
      }
    },
    estimatedCost(){
      switch (this.tankType){
        case "sewer":
          // return formatter.format((Math.round(this.baseCost * (this.sewerM3 === '1' ? 2 : this.sewerM3))));
          let sewerBase = this.baseCost * 2
          if (this.sewerM3 > 2){
            return sewerBase + ((this.sewerM3 - 2) * this.extraM3 );
          } else{
            return sewerBase;
          }
        case "tank":
          let tankBase = this.baseCost * 2
          if (parseInt(this.tankM3) > 2){
            return tankBase + ((this.tankM3 - 2) * this.extraM3 );
          } else{
            return tankBase;
          }
        default:
          return 'default'
      }
    },
    priceTotal(){
      if (this.applyIva){
        return (this.price - this.priceDiscount) +  ((this.price - this.priceDiscount)* 13/100);
      }
      else {
        return this.price - this.priceDiscount;
      }
    },
  },
  watch: {
    zone: function(val){
      let result = this.zones.find(obj => {
        return obj.code === val
      })
      this.baseCost = result.baseCost
      this.extraM3 = result.extraM3
    },
  },
  mounted() {
    //
  },
  created() {
    moment().utcOffset("-06:00");
    let self = this;
    auth.onAuthStateChanged(userAuth => {
      // console.log(userAuth.uid)
      if (userAuth) {
        this.loadZones();
      }
    });
  },
  beforeDestroy() {
    // this.$root.snapshots.availability();
    // this.$root.snapshots.blocked();
    // this.$root.snapshots.agencies();
    // this.$root.snapshots.roomData();
  },

  methods: {
    applySuggestedPrice(){
      switch (this.tankType){
        case "sewer":
          // return formatter.format((Math.round(this.baseCost * (this.sewerM3 === '1' ? 2 : this.sewerM3))));
          let sewerBase = this.baseCost * 2
          if (parseInt(this.sewerM3) > 2){
            this.price = (sewerBase + ((this.sewerM3 - 2) * this.extraM3 ));
          } else{
            this.price = sewerBase;
          }
          break
        case "tank":
          let tankBase = this.baseCost * 2
          if (parseInt(this.tankM3) > 2){
            this.price = (tankBase + ((this.tankM3 - 2) * this.extraM3 ));
          } else{
            this.price = tankBase;
          }
          break
        default:
          //
      }
    },
    async loadZones() {
      try {
        this.$root.snapshots.agencies = await db
            .collection("zones")
            .onSnapshot(docSnapshot => {
              this.zones.splice(0, this.zones.length)
              docSnapshot.forEach(doc => {
                let job = doc.data()
                job.id = doc.id
                this.zones.push(job)
              })
            });
      } catch (e) {
        console.log(e);
      }
    },
    goTop() {
      window.scrollTo(0, 0);
    },
    splitFullName() {
      let splitName = this.fullName.split(" ");
      if (splitName[0]) {
        this.firstName =
          splitName[0].charAt(0).toUpperCase() +
          splitName[0].slice(1).toLowerCase();
      }
      if (splitName[1]) {
        this.lastName =
          splitName[1].charAt(0).toUpperCase() +
          splitName[1].slice(1).toLowerCase();
      }
      if (splitName[2]) {
        this.lastName =
          splitName[1].charAt(0).toUpperCase() +
          splitName[1].slice(1).toLowerCase() +
          " " +
          splitName[2].charAt(0).toUpperCase() +
          splitName[2].slice(1).toLowerCase();
      }
      if (splitName[3]) {
        this.firstName =
          splitName[0].charAt(0).toUpperCase() +
          splitName[0].slice(1).toLowerCase() +
          " " +
          splitName[1].charAt(0).toUpperCase() +
          splitName[1].slice(1).toLowerCase();
        this.lastName =
          splitName[2].charAt(0).toUpperCase() +
          splitName[2].slice(1).toLowerCase() +
          " " +
          splitName[3].charAt(0).toUpperCase() +
          splitName[3].slice(1).toLowerCase();
      }
    },
    async submit(id) {
      let self = this;
      this.$h.showLoader();
      await db.collection('jobs').doc().set({
        fullName: this.fullName,
        address: this.address,
        cancelled: false,
        createdAt: moment().toDate(),
        date: this.date,
        time: this.time,
        estimatedPrice: this.estimatedCost,
        firstName: this.firstName,
        lastName: this.lastName,
        ivaApplied: this.applyIva,
        jobDone: false,
        m3: this.rM3,
        notes: this.notes,
        phone: this.phone,
        price: this.priceTotal,
        tankType: this.tankType,
        zone: this.zone,
        email: this.email,
      }).then(()=>{
        this.clearFields()
      })
    },
    async sendMail(email, firstName, magicLink) {
      let emailTemplate;
      let lang;
      let hotel = localStorage.getItem("hotel");
      if (spanishSpeakers.includes(this.country)) {
        lang = "es";
        emailTemplate = hotel + "-credentials-es";
      } else {
        lang = "en";
        emailTemplate = hotel + "-credentials-en";
      }
      let self = this;
      let magicLoginImg;
      let magicLoginLink;
      magicLoginImg = '<img src="' + this.$refs.magicLogin.dataUrl + '"/>';
      setTimeout(() => {
        magicLoginLink =
          '<a href="' + this.magicLogin + '">' + lang === "en"
            ? "Click here"
            : "Click aquí" + "</a>";
        db.collection("mail")
          .add({
            to: email,
            template: {
              name: emailTemplate,
              data: {
                guest_name: firstName,
                magicLoginLink: magicLink,
                magicLoginImg: magicLoginImg
              }
            }
          })
          .then(() => self.clearFields())
          .catch(e => {
            self.$h.showError(null, e);
          });
      }, 500);
      this.clearFields();
    },
    clearFields() {
      let self = this;
      self.date = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
      self.tankType = 'tank'
      self.address = '';
      self.time = '';
      self.ivaApplied = false;
      this.rM3 = null
      self.zone = 'snrmn01';
      self.priceDiscount = 0;
      self.fullName = "";
      self.firstName = "";
      self.lastName = "";
      self.phone = "";
      self.email = "";
      self.price = 0;
      self.period = 'Anual'
      self.notes = "";
      this.$h.hideLoader();
      this.$h.toast(
          "success",
          "Usuario creado satisfactoriamente",
          "top"
      );
      window.scrollTo(0, 0);
    },
    compare(a, b) {
      // console.log(a, b)
      // Use toUpperCase() to ignore character casing
      const agencyA = a.name.toUpperCase();
      const agencyB = b.name.toUpperCase();

      let comparison = 0;
      if (agencyA > agencyB) {
        comparison = 1;
      } else if (agencyA < agencyB) {
        comparison = -1;
      }
      return comparison;
    },
  }
};
</script>
