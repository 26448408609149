//FrontDesk
import TopMenu from "../layouts/TopMenu";
import Dashboard from "../views/admin/Dashboard";
import Rooms from "../views/admin/Rooms";
import Settings from "../views/admin/Settings";
import RegisterJobs from "../views/admin/RegisterJobs";
import EditJobs from "../views/admin/EditJobs";
import Credentials from "../views/admin/Credentials";

//Misc
import ErrorPage from "@/views/ErrorPage";

//Login
import Login from "@/views/Login";

let main = [
  {
    path: "/dashboard",
    name: "main",
    component: TopMenu,
    meta: { onlyWhenLoggedOut: false, requiresAuth: true },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard
      },
      {
        path: "/rooms",
        name: "Rooms",
        component: Rooms
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings
      },
      {
        path: "/register",
        name: "RegisterJobs",
        component: RegisterJobs
      },
      {
        path: "/edit",
        name: "EditJobs",
        component: EditJobs
      },
      {
        path: "/credentials",
        name: "Credentials",
        component: Credentials
      }
    ]
  }
];
let miscPages = [
  {
    path: "/misc",
    component: ErrorPage,
    children: [
      {
        path: "/error-page",
        name: "error-page",
        component: ErrorPage
      },
      {
        path: "/*",
        component: ErrorPage
      }
    ]
  }
];

let authPages = [
  {
    path: "/login",
    name: "login",
    component: Login
  }
];

const routes = [
  {
    path: "/",
    redirect: "/login",
    name: "Login"
  },
  ...authPages,
  ...miscPages,
    ...main
];

export default routes;
