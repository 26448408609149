import Vue from "vue";
import "./Highlight";
import "./LineChart";
import "./DonutChart";
import "./BarChart";
import "./PieChart";
import "./GoogleMapLoader";
import "./LitePicker";
import "./Tippy";
import "./TippyContent";
import "./TailSelect";
import "./LoadingIcon";
// Notifications plugin. Used on Notifications page

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/es";
Vue.use(ElementUI, { locale });

import Print from "vue-print-nb";
Vue.use(Print);

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);

import SweetModal from "sweet-modal-vue/src/plugin.js";
Vue.use(SweetModal);

import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import VueToast from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast);

import EasySlider from "vue-easy-slider";
Vue.use(EasySlider);

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
Vue.use(Loading, {
  color: "#ffffff",
  backgroundColor: "#000000"
});

import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
Vue.use(VueMaterial);

// import { GridPlugin } from '@syncfusion/ej2-vue-grids';
// Vue.use(GridPlugin);

//Vue Currency Filter
import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,
    {
      symbol : '₡',
      thousandsSeparator: '.',
      fractionCount: 2,
      fractionSeparator: ',',
      symbolPosition: 'front',
      symbolSpacing: false,
      avoidEmptyDecimals: undefined,
    })

Vue.filter('truncate', function (value, limit) {
    if (!!value){
        if (value.length > limit) {
            value = value.substring(0, (limit - 3)) + '...';
        }
        return value
    }
})

import VueHtmlToPaper from "vue-html-to-paper";
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    // "css/custom.css",
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ]
};

Vue.use(VueHtmlToPaper, options);

import VueOfflinePlugin from "vue-offline/src";
Vue.use(VueOfflinePlugin);

Vue.component("Highlight", () => import("./Highlight"));
Vue.component("LineChart", () => import("./LineChart"));
Vue.component("DonutChart", () => import("./DonutChart"));
Vue.component("BarChart", () => import("./BarChart"));
Vue.component("PieChart", () => import("./PieChart"));
Vue.component("GoogleMapLoader", () => import("./GoogleMapLoader"));
Vue.component("LitePicker", () => import("./LitePicker"));
Vue.component("Tippy", () => import("./Tippy"));
Vue.component("TippyContent", () => import("./TippyContent"));
Vue.component("TailSelect", () => import("./TailSelect"));
Vue.component("LoadingIcon", () => import("./LoadingIcon"));
