<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Editar trabajos</h2>
    </div>

    <div class="pos intro-y grid grid-cols-1 gap-5 mt-5">
      <!-- BEGIN: Ticket -->
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <v-data-table
              v-if="!isLoading"
              disable-pagination
              :search="search"
              :items="tableData"
              :headers="tableHeaders"
              item-key="uid"
              class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.id="{ item }">
              <v-tooltip
                  v-if="!!item.notes || !!item.inNotes || item.guestLoyaltyLevel > 0"
                  internal-activator
                  color="#222222"
                  right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="red"
                      class="cursor-pointer animate"
                      v-bind="attrs"
                      v-on="on"
                  >mdi-access-point</v-icon
                  >
                  -
                </template>
                <span class="m-2"></span>
                <div v-show="!!item.notes">
                  <h4 class="text-theme-warning font-weight-bold">
                    Notas del cliente
                  </h4>
                  <h5 class="text-white">{{ item.notes }}</h5>
                  <span class="m-2"></span>
                </div>
                <div v-show="item.guestLoyaltyLevel > 0">
                  <v-chip class="ma-2" color="green" text-color="white">
                    {{ loyaltyLevel(item.guestLoyaltyLevel) }}
                  </v-chip>
                </div>
              </v-tooltip>
              <v-tooltip
                  internal-activator
                  color="#222222"
                  top
              >
                <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="cursor-pointer">
              {{item.id | truncate(10)}}
            </span>
                </template>
                <span>{{item.id}}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.date="{ item }">
              {{ formattedDate(item.date) }}
            </template>
            <template v-slot:item.phone="{ item }">
              {{ item.phone.slice(0, 4) + '-' + item.phone.slice(4) }}
            </template>
            <template v-slot:item.zone="{ item }">
              {{ decodeZone(item.zone) }}
            </template>
            <template v-slot:item.price="{ item }">
              {{ item.price | currency }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      :disabled="item.jobDone"
                      color="green"
                      x-small
                      v-bind="attrs"
                      class="ma-1 white--text"
                      fab
                      @click="doJobDone(item.id)"
                      v-on="on"
                  >
                    <v-icon dark>
                      mdi-chevron-up
                    </v-icon>
                  </v-btn>
                </template>
                <span>Completar trabajo</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="blue"
                      x-small
                      v-bind="attrs"
                      class="ma-1 white--text"
                      fab
                      @click="handleEdit(item)"
                      v-on="on"
                  >
                    <v-icon dark>
                      mdi-book-edit
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <div v-else>
            <v-skeleton-loader
                type="table: table-heading, table-thead, table-tbody"
            ></v-skeleton-loader>
          </div>
        </div>
      </div>
      <!-- Formulario Principal -->
      <!-- END: Ticket -->
    </div>
    <v-dialog
      v-model="editModalIsOpen"
      fullscreen
      content-class="box p-10 z-top"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="registerForm" @submit.prevent="handleSubmit(updateUser)">
          <div class="intro-y col-span-12 lg:col-span-12">
            <div class="intro-y box mt-5">
              <!-- Titulo -->
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">Fechas</h2>
                <v-btn icon @click="editModalIsOpen = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <!-- Datepicker -->
              <div class="lg:flex intro-y px-5">
                <div
                  class="relative ml-auto mr-auto text-gray-700 dark:text-gray-300"
                >
                  <van-sticky>
                    <div class="intro-y nl items-center mt-5 h-10">
                      <div
                        class="flex items-center ml-auto mr-auto text-centers"
                      >
                        <calendar-icon class="mr-1"></calendar-icon>
                        <vc-date-picker placement="bottom" popover-align="center"
                                        v-model="date"
                                        :locale="'es'"
                                        class="flex-grow dateCenter"
                                        title-position="right"
                        >
                          <template v-slot="{ inputValue, togglePopover }">
                            <v-text-field
                                dense
                                outlined
                                background-color="#ffffff"
                                readonly
                                class="dateCenter mt-1"
                                :value="inputValue"
                                hide-details="auto"
                                @focus="togglePopover({ placement: 'auto-start' })"
                            ></v-text-field>
                          </template>
                        </vc-date-picker>
                        <calendar-icon class="ml-1"></calendar-icon>
                      </div>
                    </div>
                  </van-sticky>
                  <el-divider></el-divider>
                </div>
              </div>
              <!-- Segunda Sección -->
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">Datos del huésped</h2>
              </div>
              <!-- Inputs Segunda Sección -->
              <div
                class="intro-y items-center grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 px-5 mt-5 gap-5"
              >
                <!-- Nombre -->
                <div class="items-center">
                  <ValidationProvider
                    v-slot="{ passed, failed, errors }"
                    name="Nombre"
                    rules="required|min:3"
                  >
                    <md-field class="mb-0 intro-y">
                      <label>Nombre Completo</label>
                      <md-input
                        v-model="fullName"
                        required
                        @blur="splitFullName"
                      ></md-input>
                    </md-field>
                    <span class="text-theme-danger mt-0">{{
                      errors.length
                        ? "El nombre es necesario y debe contener mínimo 3 letras"
                        : ""
                    }}</span>
                  </ValidationProvider>
                </div>
                <!-- Teléfono -->
                <div class="items-center">
                  <ValidationProvider
                    v-slot="{ passed, failed, errors }"
                    name="Teléfono"
                    rules="min:4"
                  >
                    <md-field class="mb-0 intro-y">
                      <label>Teléfono</label>
                      <md-input v-model="phone"></md-input>
                    </md-field>
                    <span class="text-theme-danger mt-0">{{
                      errors.length
                        ? "El el número de teléfono debe contener mínimo 4 caracteres"
                        : ""
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Sexta Sección -->
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  Notas o detalles internos
                </h2>
              </div>
              <div
                class="grid md:grid-cols-1 sm:grid-cols-1 grid-cols-1 intro-y items-center px-5 pb-5 mb-5 mt-5 gap-5"
              >
                <!-- Notas -->
                <div class="items-center">
                  <ValidationProvider
                    v-slot="{ passed, failed, errors }"
                    name="Notas"
                    rules="min:3|max:200"
                  >
                    <md-field>
                      <label>Notas internas</label>
                      <md-textarea
                        v-model="inNotes"
                        md-autogrow
                        md-counter="200"
                      ></md-textarea>
                    </md-field>
                    <span class="text-theme-danger mt-0">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Submit -->
              <div
                class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <md-button
                  type="submit"
                  class="ml-auto mr-auto md-button-spaced md-raised md-primary"
                >
                  Actualizar
                </md-button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </v-dialog>
  </div>
</template>
<script>
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

import { db, auth, adminDB, adminAuth } from "@/firebase/config";

//Others
import countries from "@/JSON/countryLargeData.json";
import spanishSpeakers from "@/JSON/spanishSpeakenCountries.json";
import Fuse from "fuse.js";

import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  numeric,
  min_value,
  min,
  max,
  max_value
} from "vee-validate/dist/rules";
import VueQrcode from "vue-qrcode";
import Tooltip from "@/views/Tooltip";

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("numeric", numeric);
extend("min", min);
extend("min_value", min_value);
extend("max", max);
extend("max_value", max_value);
export default {
  components: {
    Tooltip,
    VueQrcode
  },
  data() {
    return {
      zones: [],
      now: moment(),
      signatureURL: "",
      fullName: "",
      date: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      search: '',
      isLoading: false,
      isNotPrinting: true,
      expanded: [],
      reservationNumber: "",
      country: "",
      phone: "",
      notes: "",
      inNotes: "",
      firstName: "",
      lastName: "",
      email: "",
      emailConfirm: "",
      password: "",
      passwordConfirm: "",
      subscribe: false,
      documentID: "",
      documentType: "",
      selects: {
        documentTypes: [
          {
            value: "dni",
            label: "ID"
          },
          {
            value: "passport",
            label: "Passport"
          },
          {
            value: "dimex",
            label: "Dimex"
          },
          {
            value: "driver_license",
            label: "Driver License"
          }
        ],
        foodPlan: [
          "Desayunos",
          "Desayunos y Almuerzo",
          "Pensión completa",
          "N/A"
        ],
        paxList: [0, 1, 2, 3, 4, 5, 6],
        paxListMin: [1, 2, 3, 4, 5, 6],
        paxListMinZero: [0, 1, 2, 3, 4, 5, 6]
      },
      emails: [],
      checkinDate: null,
      checkoutDate: null,
      roomReadyTime: null,
      disabledRooms: [],
      blockedRooms: [],
      tempBlockedRooms: [],
      matchExtraRoomAgesCount: true,
      mainRoomAdults: 1,
      mainRoomTeenagers: 0,
      mainRoomChildren: 0,
      mainRoomInfants: 0,
      extraRoomsAges: [],
      ageMainRoom: [],
      foodPlanSelected: "Desayunos",
      userID: null,
      reservationID: null,
      emailChanged: false,
      editModalIsOpen: false,
      agency: "",
      agencies: [{}],
      paxMainRoom: null,
      paxExtraRoom: [],
      tableHeaders: [
        {
          text: "# Orden",
          align: "start",
          sortable: true,
          value: "id"
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "fullName"
        },
        {
          text: "Teléfono",
          align: "start",
          sortable: true,
          value: "phone"
        },
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "date"
        },
        {
          text: "Hora",
          align: "start",
          sortable: true,
          value: "time"
        },
        {
          text: "Zona",
          align: "center",
          sortable: true,
          value: "zone"
        },

        {
          text: "Precio",
          align: "center",
          sortable: true,
          value: "price"
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions"
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  computed: {
    sanitizeArray(arr) {
      return arr.filter(function() {
        return true;
      });
    },
  },
  watch: {
    editModalIsOpen(value) {
      if (value) {
        //
      }
    },
    reservationNumber(value) {
      if (value) {
        this.reservationNumber = value.toUpperCase();
      }
    },
    carPlate(value) {
      if (value) {
        this.carPlate = value.toUpperCase();
      }
    },
    documentID(value) {
      if (value) {
        this.documentID = value.toUpperCase();
      }
    },
    extraRooms(value, oldValue) {
      let self = this;
      this.extraRoomsAges.forEach(function(value1, index) {
        if (!self.extraRooms.includes(value1.room)) {
          self.extraRoomsAges.splice(index);
        }
      });
      this.extraRooms.forEach(val => {
        if (!self.paxExtraRoom.hasOwnProperty(val)) {
          delete self.paxExtraRoom[val];
        }
      });
      // Object.keys(this.paxExtraRoom).forEach(value2 =>{
      //   if (self.extraRooms.includes(value2)){
      //     delete self.paxExtraRoom[value2]
      //   }
      // })
      // this.extraRoomsAges.splice(0, this.extraRoomsAges)
      if (value.length) {
        value.forEach(val => {
          this.tempBlockedRooms[val] = true;
          if (!this.paxExtraRoom.hasOwnProperty(val)) {
            this.paxExtraRoom[val] = 1;
          }
          if (this.extraRoomsAges[val] === undefined) {
            this.$set(this.extraRoomsAges, val, {
              //this.$set to make the nested objects reactive hell yeah!
              room: val,
              adults: 1,
              teenagers: 0,
              children: 0,
              infants: 0
            });
          }
        });
      } else {
        if (this.extraRoomsAges.length) {
          this.extraRoomsAges.splice(0, this.extraRoomsAges.length);
        }
        if (this.paxExtraRoom.length) {
          this.paxExtraRoom.splice(0, this.paxExtraRoom.length);
        }
      }
      if (oldValue.length) {
        oldValue.forEach(val => {
          if (!value.includes(val)) {
            this.disabledRooms[val] = false;
            this.tempBlockedRooms[val] = false;
          }
        });
      }
    },
    extraRoomsAges: {
      handler(value) {
        let boolEval = [];
        value.forEach(val => {
          let total =
            parseInt(val.adults) +
            parseInt(val.teenagers) +
            parseInt(val.children) +
            parseInt(val.infants);
          boolEval[val.room] = total === this.paxExtraRoom[val.room];
        });
        this.matchExtraRoomAgesCount = !boolEval.includes(false);
      },
      deep: true
    },
    paxExtraRoom: {
      handler(value) {
        let boolEval = [];
        this.extraRoomsAges.forEach(val => {
          let total =
            parseInt(val.adults) +
            parseInt(val.teenagers) +
            parseInt(val.children) +
            parseInt(val.infants);
          boolEval[val.room] = total === this.paxExtraRoom[val.room];
        });
        this.matchExtraRoomAgesCount = !boolEval.includes(false);
      },
      deep: true
    },
  },
  mounted() {
    this.$h.hideLoader();
  },
  created() {
    moment().utcOffset("-06:00");
    let self = this;
    auth.onAuthStateChanged(userAuth => {
      // console.log(userAuth.uid)
      if (userAuth) {
        this.loadZones().then(()=>{
          this.loadJobs();
        })
        this.$h.hideLoader();
        if (this.$route.params.row) {
          this.handleEdit(this.$route.params.row);
        }
      }
    });
  },
  beforeDestroy() {
    // this.$root.snapshots.roomData();
    // this.$root.snapshots.roomCapacity();
    this.$root.snapshots.zones();
    this.$root.snapshots.jobs();
    // this.$root.snapshots.reservationData();
  },
  methods: {
    setPage(page) {
      this.pagination.currentPage = page;
    },
    statusType(data) {
      if (data.cancelled) {
        return "danger";
      } else if (data.noShow) {
        return "default";
      } else if (data.checkoutIsDone) {
        return "warning";
      } else if (data.checkinIsDone && !data.checkoutIsDone) {
        return "success";
      } else if (!data.checkinIsDone && !data.checkoutIsDone) {
        return "primary";
      }
    },
    status(data) {
      // console.log(data)
      if (data.cancelled) {
        return "Cancelado";
      } else if (data.noShow) {
        return "No Show";
      } else if (data.checkoutIsDone) {
        return "Checkout";
      } else if (data.checkinIsDone && !data.checkoutIsDone) {
        return "In House";
      } else if (!data.checkinIsDone && !data.checkoutIsDone) {
        return "Pendiente";
      }
    },
    splitFullName() {
      let splitName = this.fullName.split(" ");
      if (splitName[0]) {
        this.firstName =
          splitName[0].charAt(0).toUpperCase() +
          splitName[0].slice(1).toLowerCase();
      }
      if (splitName[1]) {
        this.lastName =
          splitName[1].charAt(0).toUpperCase() +
          splitName[1].slice(1).toLowerCase();
      }
      if (splitName[2]) {
        this.lastName =
          splitName[1].charAt(0).toUpperCase() +
          splitName[1].slice(1).toLowerCase() +
          " " +
          splitName[2].charAt(0).toUpperCase() +
          splitName[2].slice(1).toLowerCase();
      }
      if (splitName[3]) {
        this.firstName =
          splitName[0].charAt(0).toUpperCase() +
          splitName[0].slice(1).toLowerCase() +
          " " +
          splitName[1].charAt(0).toUpperCase() +
          splitName[1].slice(1).toLowerCase();
        this.lastName =
          splitName[2].charAt(0).toUpperCase() +
          splitName[2].slice(1).toLowerCase() +
          " " +
          splitName[3].charAt(0).toUpperCase() +
          splitName[3].slice(1).toLowerCase();
      }
    },
    // updateMaster(){
    //   this.tableData.forEach(item => {
    //     db
    //       .collection("hpp")
    //       .doc('guest')
    //       .collection("reservations")
    //       .doc(item.id)
    //       .update({
    //         preCheckin: false,
    //       }).then(res=>{
    //         console.log(res + ' updated')
    //     })
    //   })
    // },
    formattedDate(date) {
      if (date) {
        return moment(date.seconds * 1000).format("DD/MM/YY");
      }
    },
    clearFields() {
      // console.log('Profile created');
      // self.$set(self.disabledRooms, [{}])
      this.extraRoomsAges.splice(0, this.extraRoomsAges);
      this.roomReadyTime = null;
      this.checkinDate = null;
      this.checkoutDate = null;
      this.firstName = null;
      this.lastName = null;
      this.documentType = null;
      this.documentID = null;
      this.country = null;
      this.phone = null;
      this.roomNumber = null;
      this.paxMainRoom = 1;
      // this.extraRooms = [];
      this.extraRooms.splice(0, this.extraRooms);
      // this.paxExtraRoom = [];
      this.paxExtraRoom.length = 0;
      this.reservationNumber = null;
      this.mainRoomAdults = 1;
      this.mainRoomTeenagers = 0;
      this.mainRoomChildren = 0;
      this.mainRoomInfants = 0;
      // this.extraRoomsAges = [];
      this.extraRoomsAges.splice(0, this.extraRoomsAges);
      this.foodPlanSelected = "Desayunos";
      this.agency = null;
      this.email = "";
      this.emailConfirm = "";
      this.notes = "";
      this.signatureURL = "";
      this.emailChanged = false;
      this.extraRoomsAges.splice(0, this.extraRoomsAges);
      this.dates = [
        moment()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate(),
        moment()
          .add(1, "days")
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate()
      ];
      this.closeEditModal();
      this.$h.toast("success", "Actualizado correctamente", "bottom");
      this.$h.hideLoader();
    },
    closeEditModal() {
      let self = this;
      this.$h.hideLoader();
      this.editModalIsOpen = false;
    },
    prefillEditForm(row) {
      this.fullName = row.fullName;
      this.phone = row.phone;
      this.zone = row.zone;
      this.notes = row.notes;
      this.date = row.date.toDate();
    },
    handleEdit(row) {
      let self = this;
      this.$swal
        .fire({
          title: "Estás segur@?",
          text: `Editarás el registro de ${row.firstName + " " + row.lastName}`,
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton:
              "button m-2 button--sm text-white bg-theme-1 align-top",
            cancelButton:
              "button m-2 button--sm text-white bg-theme-0 align-top"
          },
          confirmButtonText: "Si, estoy segur@",
          buttonsStyling: true
        })
        .then(result => {
          if (result.value) {
            this.editModalIsOpen = true;
            this.prefillEditForm(row);
          }
        });
    },
    handleDelete(row) {
      let self = this;
      // console.log(row.id)
      // console.log(row.uid)
      this.$swal
        .fire({
          title: "Estas segur@?",
          text:
            `Esto no se puede revertir!, estás a punto de eliminar todos los datos de ` +
            row.fullName,
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton:
              "button m-2 button--sm text-white bg-theme-1 align-top",
            cancelButton:
              "button m-2 button--sm text-white bg-theme-0 align-top"
          },
          confirmButtonText: "Si, sé lo que estoy haciendo!",
          buttonsStyling: true
        })
        .then(result => {
          if (result.value) {
            let deleteUser = db.collection("manageUser").doc();
            let reservation = db
              .collection(localStorage.getItem("hotel"))
              .doc("guest")
              .collection("reservations")
              .doc(row.id);
            let profile = db
              .collection(localStorage.getItem("hotel"))
              .doc("guest")
              .collection("profile")
              .doc(row.uid);
            let userBed = db.collection("user_bed").doc(row.uid);

            reservation.delete();
            profile.delete();
            userBed.delete();

            deleteUser.set({
              email: row.email,
              action: "delete"
            });
            this.$h.toast("success", "Se eliminó el usuario", "bottom");
          }
        });
    },
    compare(a, b) {
      // console.log(a, b)
      // Use toUpperCase() to ignore character casing
      const agencyA = a.name.toUpperCase();
      const agencyB = b.name.toUpperCase();

      let comparison = 0;
      if (agencyA > agencyB) {
        comparison = 1;
      } else if (agencyA < agencyB) {
        comparison = -1;
      }
      return comparison;
    },
    async loadZones() {
      try {
        this.$root.snapshots.zones = await db
            .collection("zones")
            .onSnapshot(docSnapshot => {
              docSnapshot.forEach(doc => {
                let job = doc.data()
                job.id = doc.id
                this.zones.push(job)
              })
            });
      } catch (e) {
        console.log(e);
      }
    },
    async loadJobs() {
      let self = this;
      let selectedDate = this.$store.state.main.selectedDate;
      let now = moment(selectedDate);
      this.$root.snapshots.jobs = await db
          .collection('jobs')
          .orderBy("price")
          .onSnapshot(docSnapshot => {
            this.isLoading = true;
            self.tableData.splice(0, self.tableData.length);
            self.tableData.length = 0;
            this.$root.dataBus.totalCheckins = 0;
            // self.tableData = [];
            docSnapshot.forEach(doc => {
              let job = doc.data();
              job.id = doc.id;
              self.tableData.push(job);
            });
            this.isLoading = false;
          });
    },
    decodeZone(code) {
      let index = this.zones.findIndex(x => x.code === code);
      // console.log(index)
      if (index >= 0){
        return this.zones[index].name.toString()
      }
    },
    async doJobDone(id) {
      this.$swal
          .fire({
            title: "Estas seguro?",
            text: `Esto marcará el trabajo como completado`,
            icon: "question",
            showCancelButton: true,
            customClass: {
              confirmButton:
                  "button m-2 button--sm text-white bg-theme-1 align-top",
              cancelButton:
                  "button m-2 button--sm text-white bg-theme-0 align-top"
            },
            confirmButtonText: "Si, estoy seguro",
            buttonsStyling: true
          })
          .then(result => {
            if (result.value) {
              try {
                db.collection('jobs')
                    .doc(id)
                    .update({
                      jobDone: true,
                    });
                this.$h.toast(
                    "success",
                    "Trabajo completado correctamente",
                    "bottom"
                );
              } catch (e) {
                this.$h.showError(null, e);
              }
            }
          });
    },
    loyaltyLevel(level) {
      switch (level) {
        case 1:
          return "Cliente Recurrente";
        case 2:
          return "Cliente Frecuente";
        case 3:
          return "Cliente Premium";
        default:
          return "Nuevo cliente";
      }
    },
  }
};
</script>
