<template>
  <div>
    <!--    <DarkModeSwitcher />-->
    <div class="hidden-md-and-up">
      <v-app-bar
          color="transparent"
          dark
      >
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

        <v-toolbar-title class="mx-auto">Rapi CR</v-toolbar-title>

          <!-- BEGIN: Account Menu -->
          <div class="intro-x dropdown w-8 h-8 relative ml-auto">
            <div
                class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
            >
              <img
                  alt="Proflie"
                  :src="require(`@/assets/images/brand/logo.png`)"
              />
            </div>
            <div class="dropdown-box w-56">
              <div
                  class="dropdown-box__content box bg-theme-38 dark:bg-dark-6 text-white"
              >
                <div class="p-4 border-b border-theme-gray dark:border-dark-3">
                  <div class="font-medium">
                    {{ getName() }}
                  </div>
                  <div class="text-xs text-theme-41 dark:text-gray-600">
                    Bienvenid@!
                  </div>
                </div>
                <!--              <div class="p-2">-->
                <!--                <a-->
                <!--                  href=""-->
                <!--                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"-->
                <!--                >-->
                <!--                  <UserIcon class="w-4 h-4 mr-2" /> Perfil-->
                <!--                </a>-->
                <!--                <a-->
                <!--                  href=""-->
                <!--                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"-->
                <!--                >-->
                <!--                  <LockIcon class="w-4 h-4 mr-2" /> Cambiar contraseña-->
                <!--                </a>-->
                <!--                <a-->
                <!--                  href=""-->
                <!--                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"-->
                <!--                >-->
                <!--                  <HelpCircleIcon class="w-4 h-4 mr-2" />-->
                <!--                  Ayuda-->
                <!--                </a>-->
                <!--              </div>-->
                <div class="p-2 border-t border-theme-gray dark:border-dark-3">
                  <a
                      href="javascript:void(0)"
                      class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                      @click="logOutInit"
                  >
                    <ToggleRightIcon class="w-4 h-4 mr-2 text-white" />
                    <h4 class="text-white">Salir</h4>
                  </a>
                  <a href="javascript:void(0)" class="p-2 flex items-center block transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                    <h4 class="text-theme-warning text-xs">Version 1.0.1 | 2021</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Account Menu -->
      </v-app-bar>
      <v-navigation-drawer
          class="ztop"
          v-model="drawer"
          absolute
          temporary
      >
        <v-list
            nav
            dense
        >
          <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Nuevo Trabajo</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Editar Trabajo</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
    <!-- BEGIN: Top Bar -->

    <div class="border-b hidden-sm-and-down border-theme-gray -mt-5 md:-mt-5 sm:px-8 pt-3 md:pt-0 mb-3">
      <div class="top-bar-boxed mt-1 flex items-center">
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'Dashboard' }"
          tag="a"
          class="-intro-x hidden md:flex"
        >
          <img
            width="85px"
            alt="RapiLogo"
            src="@/assets/images/brand/rp.png"
          />
        </router-link>
        <!-- END: Logo -->
        <!-- BEGIN: Breadcrumb -->
<!--        <BarChart2Icon-->
<!--          class="flex mr-auto md:hidden w-8 h-8 text-white transform -rotate-90"-->
<!--          @click="toggleMobileMenu"-->
<!--        />-->
<!--        <printer-icon-->
<!--          v-show="!this.$isMobile()"-->
<!--          class="text-white text-center md:ml-10 md:mr-auto cursor-pointer sm:ml-auto sm:mr-auto intro-y"-->
<!--          @click="printModalIsOpen = true"-->
<!--        ></printer-icon>-->
        <h3
          v-show="!this.$isMobile()"
          style="margin-left: -18%"
          class="text-xl font-medium leading-none text-white"
        >
        </h3>
        <!-- END: Breadcrumb -->
        <!-- BEGIN: Account Menu -->
        <div class="intro-x dropdown w-8 h-8 relative ml-auto">
          <div
            class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
          >
            <img
              alt="Proflie"
              :src="require(`@/assets/images/brand/logo.png`)"
            />
          </div>
          <div class="dropdown-box w-56">
            <div
              class="dropdown-box__content box bg-theme-38 dark:bg-dark-6 text-white"
            >
              <div class="p-4 border-b border-theme-gray dark:border-dark-3">
                <div class="font-medium">
                  {{ getName() }}
                </div>
                <div class="text-xs text-theme-41 dark:text-gray-600">
                  Bienvenid@!
                </div>
              </div>
              <!--              <div class="p-2">-->
              <!--                <a-->
              <!--                  href=""-->
              <!--                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"-->
              <!--                >-->
              <!--                  <UserIcon class="w-4 h-4 mr-2" /> Perfil-->
              <!--                </a>-->
              <!--                <a-->
              <!--                  href=""-->
              <!--                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"-->
              <!--                >-->
              <!--                  <LockIcon class="w-4 h-4 mr-2" /> Cambiar contraseña-->
              <!--                </a>-->
              <!--                <a-->
              <!--                  href=""-->
              <!--                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"-->
              <!--                >-->
              <!--                  <HelpCircleIcon class="w-4 h-4 mr-2" />-->
              <!--                  Ayuda-->
              <!--                </a>-->
              <!--              </div>-->
              <div class="p-2 border-t border-theme-gray dark:border-dark-3">
                <a
                  href="javascript:void(0)"
                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="logOutInit"
                >
                  <ToggleRightIcon class="w-4 h-4 mr-2 text-white" />
                  <h4 class="text-white">Salir</h4>
                </a>
                <a href="javascript:void(0)" class="p-2 flex items-center block transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                  <h4 class="text-theme-warning text-xs">1.0.1 | 2021</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Account Menu -->
      </div>
    </div>
    <!-- END: Top Bar -->
    <!-- BEGIN: Top Menu -->
    <nav style="z-index: 100;" class="top-nav">
      <ul>
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <li :key="menuKey">
            <a
              href="javascript:;"
              class="top-menu"
              :class="{
                'top-menu--active': menu.active
              }"
              @click="linkTo(menu)"
            >
              <div class="top-menu__icon">
                <component :is="menu.icon" />
              </div>
              <div class="top-menu__title">
                {{ menu.title }}
                <ChevronDownIcon
                  v-if="$h.isset(menu.subMenu)"
                  class="top-menu__sub-icon"
                />
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <ul v-if="$h.isset(menu.subMenu)">
              <li
                v-for="(subMenu, subMenuKey) in menu.subMenu"
                :key="subMenuKey"
              >
                <a
                  href="javascript:;"
                  class="top-menu"
                  @click="linkTo(subMenu)"
                >
                  <div class="top-menu__icon">
                    <ActivityIcon />
                  </div>
                  <div class="top-menu__title">
                    {{ subMenu.title }}
                    <ChevronDownIcon
                      v-if="$h.isset(subMenu.subMenu)"
                      class="top-menu__sub-icon"
                    />
                  </div>
                </a>
                <!-- BEGIN: Third Child -->
                <ul v-if="$h.isset(subMenu.subMenu)">
                  <li
                    v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                    :key="lastSubMenuKey"
                  >
                    <a
                      href="javascript:;"
                      class="top-menu"
                      @click="linkTo(lastSubMenu)"
                    >
                      <div class="top-menu__icon">
                        <component :is="'zap-icon'" />
                      </div>
                      <div class="top-menu__title">
                        {{ lastSubMenu.title }}
                      </div>
                    </a>
                  </li>
                </ul>
                <!-- END: Third Child -->
              </li>
            </ul>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
        <li v-if="noInternet" index="10">
          <i
            style="color: red!important;"
            class="ml-auto mr-auto mt-3 text-2xl fa fa-plane"
          ></i>
          <h4 class="text-white float-right ml-3 mt-4">Sin internet</h4>
        </li>
      </ul>
    </nav>
    <!-- END: Top Menu -->
    <!-- BEGIN: Content -->
    <div class="content">
      <router-view />
    </div>
    <v-dialog
      v-model="printModalIsOpen"
      fullscreen
      content-class="box p-10 z-top"
      style="z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-btn class="float-right d-print-none" @click="printModalIsOpen = false" right fab x-small color="error"><v-icon>mdi-close</v-icon></v-btn>
      <div id="printStatus" class="row">
        <h4 class="font-weight-bold text-xl text-center">
          {{"Hoja de Estado - " + formatMainDate($root.dataBus.dashboardDate) }}
          | Rapi CR
        </h4>
        <div class="col-12">
          <table class="col-12 mb-4">
            <thead>
              <h4 class="font-weight-bold">Trabajos</h4>
            </thead>
            <tr>
              <th>Nombre</th>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Extra Rooms</th>
              <th>Agencia</th>
              <th>Total Pax</th>
            </tr>
            <tr
              v-for="(row, index) in $root.dataBus.checkinsTable"
              :key="index"
            >
              <td>{{ row.reservationNumber }}</td>
              <td>{{ row.fullName }}</td>
              <td>
                {{ formattedDate(row.checkinDate) }} -
                {{ formattedDate(row.checkoutDate) }}
              </td>
              <td>{{ row.roomNumber }}</td>
              <td>{{ row.extra_rooms || "N/A" }}</td>
              <td>{{ $root.dataBus.agencies[row.agencyCode] || "N/A" }}</td>
              <td>{{ totalPax(row) }}</td>
            </tr>
          </table>

          <table v-if="$root.dateHasInHouse" class="col-12 mb-4">
            <thead>
              <h4 class="font-weight-bold">In House</h4>
            </thead>
            <tr>
              <th># Reserva</th>
              <th>Nombre</th>
              <th>Fechas</th>
              <th>Room</th>
              <th>Extra Rooms</th>
              <th>Agencia</th>
              <th>Total Pax</th>
            </tr>
            <tr v-for="(row, index) in $root.dataBus.inHouseTable" :key="index">
              <td>{{ row.reservationNumber }}</td>
              <td>{{ row.fullName }}</td>
              <td>
                {{ formattedDate(row.checkinDate) }} -
                {{ formattedDate(row.checkoutDate) }}
              </td>
              <td>{{ row.roomNumber }}</td>
              <td>{{ row.extra_rooms || "N/A" }}</td>
              <td>{{ $root.dataBus.agencies[row.agencyCode] || "N/A" }}</td>
              <td>{{ totalPax(row) }}</td>
            </tr>
          </table>

          <table v-if="$root.dateHasCheckouts" class="col-12 mb-4">
            <thead>
              <h4 class="font-weight-bold">Checkouts</h4>
            </thead>
            <tr>
              <th># Reserva</th>
              <th>Nombre</th>
              <th>Fechas</th>
              <th>Room</th>
              <th>Extra Rooms</th>
              <th>Agencia</th>
              <th>Total Pax</th>
            </tr>
            <tr
              v-for="(row, index) in $root.dataBus.checkoutsTable"
              :key="index"
            >
              <td>{{ row.reservationNumber }}</td>
              <td>{{ row.fullName }}</td>
              <td>
                {{ formattedDate(row.checkinDate) }} -
                {{ formattedDate(row.checkoutDate) }}
              </td>
              <td>{{ row.roomNumber }}</td>
              <td>{{ row.extra_rooms || "N/A" }}</td>
              <td>{{ $root.dataBus.agencies[row.agencyCode] || "N/A" }}</td>
              <td>{{ totalPax(row) }}</td>
            </tr>
          </table>

          <table v-if="$root.dateHasNoShowReservations" class="col-12 mb-4">
            <thead>
              <h4 class="font-weight-bold">NO SHOW</h4>
            </thead>
            <tr>
              <th># Reserva</th>
              <th>Nombre</th>
              <th>Fechas</th>
              <th>Room</th>
              <th>Extra Rooms</th>
              <th>Agencia</th>
              <th>Total Pax</th>
            </tr>
            <tr v-for="(row, index) in $root.dataBus.noShowTable" :key="index">
              <td>{{ row.reservationNumber }}</td>
              <td>{{ row.fullName }}</td>
              <td>
                {{ formattedDate(row.checkinDate) }} -
                {{ formattedDate(row.checkoutDate) }}
              </td>
              <td>{{ row.roomNumber }}</td>
              <td>{{ row.extra_rooms || "N/A" }}</td>
              <td>{{ $root.dataBus.agencies[row.agencyCode] || "N/A" }}</td>
              <td>{{ totalPax(row) }}</td>
            </tr>
          </table>

          <table v-if="$root.dateHasCancelledReservations" class="col-12 mb-4">
            <thead>
              <h4 class="font-weight-bold">Canceladas</h4>
            </thead>
            <tr>
              <th># Reserva</th>
              <th>Nombre</th>
              <th>Fechas</th>
              <th>Room</th>
              <th>Extra Rooms</th>
              <th>Agencia</th>
              <th>Total Pax</th>
            </tr>
            <tr
              v-for="(row, index) in $root.dataBus.cancelledTable"
              :key="index"
            >
              <td>{{ row.reservationNumber }}</td>
              <td>{{ row.fullName }}</td>
              <td>
                {{ formattedDate(row.checkinDate) }} -
                {{ formattedDate(row.checkoutDate) }}
              </td>
              <td>{{ row.roomNumber }}</td>
              <td>{{ row.extra_rooms || "N/A" }}</td>
              <td>{{ $root.dataBus.agencies[row.agencyCode] || "N/A" }}</td>
              <td>{{ totalPax(row) }}</td>
            </tr>
          </table>

          <v-divider></v-divider>
        </div>
        <div class="row grid grid-rows-1 ml-auto mr-auto d-print-none">
          <v-btn color="info"
            class="text-center ml-auto mr-auto"
            fab
            @click="printSection('printStatus')"
            ><v-icon>mdi-printer-check</v-icon>
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <!-- END: Content -->
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import MobileMenu from "@/components/MobileMenu";
import DarkModeSwitcher from "@/components/DarkModeSwitcher";
import { db, auth } from "@/firebase/config";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
export default {
  components: {
    DarkModeSwitcher,
    MobileMenu,
    TopBar
  },
  data() {
    return {
      drawer: false,
      group: null,
      printModalIsOpen: false,
      formattedMenu: [],
      searchDropdown: false,
      agencies: []
    };
  },
  computed: {
    topMenu() {
      return this.nestedMenu(this.$store.state.TopMenu.menu);
    },
    loggedIn() {
      // return this.$store.state.main.isLoggedIn
    },
    noInternet() {
      return this.isOffline;
    }
  },
  watch: {
    // loggedIn(val){
    //   if (val){
    //     this.loadAgencies();
    //   }
    // },
    $route() {
      this.formattedMenu = this.$h.assign(this.topMenu);
    }
  },
  mounted() {
    cash("body")
      .removeClass("login")
      .addClass("app");
    this.formattedMenu = this.$h.assign(this.topMenu);
  },
  created() {
    auth.onAuthStateChanged(user => {
      this.isLoggedIn = !!user;
      if (!this.isLoggedIn) {
        localStorage.clear();
        sessionStorage.clear();
        // this.$router.replace({name: "Login"})
        window.location.reload(true); //We need to reload to destroy all instances and let the app to make them again on new login
      } else {
        this.loadAgencies();
      }
      // user ? this.loggedIn = true : this.loggedIn = false;
    });
  },
  beforeDestroy() {
    this.$root.snapshots.agencies();
  },
  methods: {
    printSection(id) {
      this.$htmlToPaper(id);
      // printJS(id, 'html')
    },
    toggleMobileMenu() {
      this.$root.activeMobileMenu = !this.$root.activeMobileMenu;
    },
    totalPax(row) {
      let mainPax = row.paxMainRoom;
      let extraPax = 0;
      if (!!row.paxMainRoom && !!row.paxExtraRoom && !!row.extra_rooms) {
        Object.keys(row.paxExtraRoom).forEach(item => {
          // console.log(row.paxExtraRoom[item])
          // console.log(row.paxExtraRoom[item].paxNumber)
          extraPax = extraPax + row.paxExtraRoom[item];
        });
      }
      return mainPax + extraPax;
    },
    formattedDate(date) {
      if (date) {
        return moment(date.seconds * 1000).format("DD/MM/YY");
      }
    },
    formatMainDate(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY");
      }
    },
    closePrintModal() {
      this.printModalIsOpen = false;
    },
    prepareForPrinting() {
      let element = document.querySelectorAll("h1, h2, h3, h4, h5, h6, p, li");
      console.log(element.classList);
      element.classList.add("boldText");
    },
    async loadAgencies() {
      // try {
      //   this.$root.snapshots.agencies = await db
      //     .collection("misc_data")
      //     .doc("origin")
      //     .onSnapshot(doc => {
      //       doc.data()[localStorage.getItem("hotel")].forEach(value => {
      //         this.agencies[value.code] = value.name;
      //         this.$root.dataBus.agencies[value.code] = value.name;
      //       });
      //     });
      // } catch (e) {
      //   await this.$h.showError(null, e);
      // }
    },
    logOutInit() {
      this.$swal({
        icon: "question",
        title: "Salir del sistema",
        text: "Estás segur@ que deseas salir del sistema?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cerrar sesión",
        cancelButtonText: "Cancelar",
        showClass: {
          popup: "animate__animated animate__backInDown"
        },
        hideClass: {
          popup: "animate__animated animate__backOutDown"
        }
      }).then(result => {
        if (result.value) {
          this.signOut();
        }
      });
    },
    async signOut() {
      try {
        await auth.signOut().then(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.$router.replace("/login");
        });
      } catch (e) {
        this.$h.showError(null, e);
      }
    },
    getName() {
      return this.$root.userData.fullName;
    },
    showSearchDropdown() {
      this.searchDropdown = true;
    },
    hideSearchDropdown() {
      this.searchDropdown = false;
    },
    nestedMenu(menu) {
      menu.forEach((item, key) => {
        if (typeof item !== "string") {
          menu[key].active =
            (item.pageName == this.$route.name ||
              (this.$h.isset(item.subMenu) &&
                this.findActiveMenu(item.subMenu))) &&
            !item.ignore;
        }

        if (this.$h.isset(item.subMenu)) {
          menu[key] = {
            ...item,
            ...this.nestedMenu(item.subMenu)
          };
        }
      });

      return menu;
    },
    findActiveMenu(subMenu) {
      let match = false;
      subMenu.forEach(item => {
        item.ignore = false;
        console.log(item.ignore);
        if (item.pageName == this.$route.name && !item.ignore) {
          match = true;
        } else if (!match && this.$h.isset(item.subMenu)) {
          match = this.findActiveMenu(item.subMenu);
        }
      });
      return match;
    },
    linkTo(menu) {
      if (!this.$h.isset(menu.subMenu)) {
        if (this.$route.name !== menu.pageName) {
          this.$router.push({
            name: menu.pageName
          });
        }
      }
    }
  }
};
</script>
<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
