<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
      <!-- BEGIN: General Report -->
      <div class="col-span-12 mt-8">
        <h2 class="text-lg font-medium truncate mr-5 text-center mb-2">
          Fecha a visualizar
        </h2>
        <van-sticky>
          <div
            style="z-index: 99!important;"
            class="intro-y flex items-center mt-5 h-10"
          >
            <div class="ml-auto mr-0">
              <v-btn
                color="brown"
                x-small
                class="ma-1 white--text"
                fab
                @click="moveDate('prev')"
              >
                <v-icon dark>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </div>
            <div class="col-md-2 col-6 mx-0 dateCenter text-center">

              <vc-date-picker placement="bottom" popover-align="center"
                v-model="date"
                :locale="'es'"
                class="flex-grow dateCenter"
                title-position="right"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <v-text-field
                    dense
                    outlined
                    background-color="#ffffff"
                    readonly
                    class="dateCenter mt-1"
                    :rules="rules"
                    :value="inputValue"
                    hide-details="auto"
                    @focus="togglePopover({ placement: 'auto-start' })"
                  ></v-text-field>
                </template>
              </vc-date-picker>
            </div>
            <div class="mr-auto ml-0">
              <v-btn
                color="brown"
                x-small
                class="ma-1 white--text"
                fab
                @click="moveDate('next')"
              >
                <v-icon dark>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>
          </div>
        </van-sticky>
        <hr
          class="mt-5 mb-5"
          style="height:2px;border-width:0;color:#cbcbcb;background-color:#dbdbdb"
        />
      </div>
      <!-- END: General Report -->
    </div>
    <div v-if="!isLoading" class="col-span-12">
      <PendingJobs
        class="intro-y"
        :selected-date="date"
      ></PendingJobs>
      <el-divider></el-divider>
    </div>
    <div v-else class="col-span-12">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      ></v-skeleton-loader>
    </div>
    <div v-if="false" class="col-span-4 p-5 box">
      <pie-chart v-if="!pieIsLoading" :data="chartData" :options="chartOptions"></pie-chart>
      <v-skeleton-loader v-else type="image"></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
import PendingJobs from "@/views/admin/PendingJobs";
import PieChart from "./charts/PieChart.js";
export default {
  components: {
    PieChart,
    VueSlickCarousel,
    PendingJobs,
  },
  mounted() {
    //
  },
  data() {
    return {
      pieIsLoading: false,
      chartOptions: {
        hoverBorderWidth: 20
      },
      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: ["Adultos", "Adolescentes", "Niñ@s", "Infantes"],
        datasets: [
          {
            backgroundColor: ["#3b3b3b", "#b35c30", "#d4363a", "#26aebf"],
            data: [this.$root.dataBus.totalAdultsInHouse || 0, this.$root.dataBus.totalTeenagersInHouse || 0, this.$root.dataBus.totalKidsInHouse || 0, this.$root.dataBus.totalInfantsInHouse || 0]
          }
        ]
      },
      isLoading: false,
      rules: [
        value => !!value || "Required.",
        value => (value && value.length >= 3) || "Min 3 characters"
      ],
      calendarConfigs: {
        isModal: true,
        isDatePicker: true,
        sundayStart: false,
        dateFormat: "dd/mm/yyyy",
        isDateRange: false
      },
      salesReportFilter: "",
      date: moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      statsCards: [
        {
          title: 1,
          subTitle: "Checkins",
          type: "success",
          icon: "fa fa-chevron-up",
          footerStart: "Pendientes: ",
          footerEnd: ""
        },
        {
          title: 2,
          subTitle: "Checkouts",
          type: "warning",
          icon: "fa fa-chevron-down",
          footerStart: "Pendientes: ",
          footerEnd: ""
        },
        {
          title: 3,
          subTitle: "Rooms In-House",
          type: "primary",
          icon: "fa fa-house-user",
          footerStart: "Pronóstico:  ",
          footerEnd: ""
        },
        {
          title: 4,
          subTitle: "Pax In-House",
          type: "info",
          icon: "fa fa-users",
          footerStart: "Pronóstico: ",
          footerEnd: ""
        }
      ]
    };
  },
  computed: {
    totalAdults() {
      return this.$root.dataBus.totalAdultsInHouse
    },
    totalTeens() {
      return this.$root.dataBus.totalTeenagersInHouse
    },
    totalKids() {
      return this.$root.dataBus.totalKidsInHouse
    },
    totalInfants() {
      return this.$root.dataBus.totalInfantsInHouse
    },
  },
  watch: {
    totalAdults(value) {
      this.pieIsLoading = true;
      setTimeout(()=>{
        this.chartData.datasets[0].data[0] = value
        this.pieIsLoading = false;
      })
    },
    totalTeens(value) {
      this.pieIsLoading = true;
      setTimeout(()=>{
        this.chartData.datasets[0].data[1] = value
        this.pieIsLoading = false;
      })
    },
    totalKids(value) {
      this.pieIsLoading = true;
      setTimeout(()=>{
        this.chartData.datasets[0].data[2] = value
        this.pieIsLoading = false;
      })
    },
    totalInfants(value) {
      this.pieIsLoading = true;
      setTimeout(()=>{
        this.chartData.datasets[0].data[3] = value
        this.pieIsLoading = false;
      })
    },
    date: function(val) {
      this.isLoading = true;
      this.$h.showLoader();
      this.$store.dispatch("main/setSelectedDate", val);
      // Set today as default date if input is cleared
      if (!val) {
        this.date = moment()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate();
      }
      this.$root.dataBus.dashboardDate = moment(new Date(val)).toDate();
      setTimeout(() => {
        this.isLoading = false;
        this.$h.hideLoader();
      }, 1500);
    }
  },
  created() {
    this.$store.dispatch(
      "main/setSelectedDate",
      (this.date = moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate())
    );
  },
  methods: {
    getTotalsStrings(type) {
      switch (type) {
        case 1: //Checkins
          return this.$root.dataBus.totalCheckins.toString();
        case 2:
          return this.$root.dataBus.totalCheckouts.toString();
        case 3:
          return this.$root.dataBus.totalRoomsInHouse.toString();
        case 4:
          return this.$root.dataBus.totalPaxInHouse.toString();
        default:
        //
      }
      // console.log(this.$root.dataBus.totalCheckins)
      return this.$root.dataBus.totalCheckins.toString();
    },
    cardFooterData(title) {
      switch (title) {
        case 1:
          return this.$root.dataBus.totalCheckins;
        case 2:
          return this.$root.dataBus.checkoutsLeft;
        case 3:
          return this.$root.dataBus.roomsInHouse;
        case 4:
          return this.$root.dataBus.paxInHouse;
        default:
        //
      }
    },
    moveDate(direction) {
      this.isLoading = true;
      // this.$h.showLoader()
      switch (direction) {
        case "prev":
          this.date = moment(this.date)
            .subtract(1, "days")
            .toDate();
          break;
        case "next":
          this.date = moment(this.date)
            .add(1, "days")
            .toDate();
          break;
        default:
        //
      }
      setTimeout(() => {
        // this.$h.hideLoader()
        this.isLoading = false;
      }, 1500);
    },
    prevImportantNotes() {
      this.$refs["important-notes"].prev();
    },
    nextImportantNotes() {
      this.$refs["important-notes"].next();
    }
  }
};
</script>
