<template>
  <v-app>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <div id="app" class="mainWrapper">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { auth, db } from "@/firebase/config";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      //
    };
  },
  created() {
    auth.onAuthStateChanged(user => {
      if (user) {
        //
      }
    });
  },
  methods: {
    //
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Material+Icons");
</style>
