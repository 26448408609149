<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Credenciales</h2>
    </div>
    <div class="intro-y text-center mb-0 items-center ">
      <slider
        animation="fade"
        :indicators="false"
        :control-btn="false"
        height="50px"
        :autoplay="true"
        :touch="false"
        :speed="1200"
      >
        <slider-item
          v-for="(welcomeMessage, index) in welcomeMessages"
          :key="index"
        >
          <h3 class="text-gray-500 text-xl font-medium text-theme-info">
            {{ welcomeMessage }}
          </h3>
        </slider-item>
      </slider>
    </div>
    <div class="pos intro-y grid grid-cols-1 gap-5 mt-0">
      <!-- BEGIN: Ticket -->
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div class="grid grid-cols-2 gap-5 box mb-5 px-5">
            <el-select
              v-model="pagination.perPage"
              class="mb-3"
              :default-first-option="true"
              placeholder="Per page"
            >
              <el-option
                v-for="item in pagination.perPageOptions"
                :key="item"
                class="select-primary"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <md-field class="intro-y">
              <label>Buscar huésped</label>
              <md-input
                v-model="searchQuery"
                aria-controls="datatables"
              ></md-input>
            </md-field>
          </div>
          <div class="col-span-12">
            <el-table
              :data="searchGuest"
              :default-sort="{ prop: 'firstName', order: 'ascending' }"
            >
              <el-table-column
                :min-width="100"
                align="center"
                label="Credenciales"
                fixed
              >
                <div slot-scope="props">
                  <md-button
                    class="md-dense md-icon-button md-primary"
                    @click.native="handleEdit(props.$index, props.row)"
                  >
                    <md-icon>lock</md-icon>
                  </md-button>
                </div>
              </el-table-column>
              <el-table-column :min-width="145" label="Fechas" align="center">
                <template slot-scope="scope">
                  {{ formattedDate(scope.row.checkinDate) }} -
                  {{ formattedDate(scope.row.checkoutDate) }}
                </template>
              </el-table-column>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                sortable
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
                show-overflow-tooltip
              >
              </el-table-column>
            </el-table>
          </div>
          <div class="col-span-12 box mt-1 p-10">
            <p class="float-left">
              Mostrando de {{ from + 1 }} a {{ to }} de un total de
              {{ total }} registros
            </p>
            <el-pagination
              background
              class="float-right"
              layout="prev, pager, next"
              :page-size="pagination.perPage"
              :current-page="pagination.currentPage"
              :total="total"
              @next-click="pagination.currentPage++"
              @prev-click="
                pagination.currentPage > 0
                  ? pagination.currentPage--
                  : (pagination.currentPage = 1)
              "
              @current-change="setPage"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- Formulario Principal -->
      <!-- END: Ticket -->
    </div>
    <el-dialog
      :modal-append-to-body="false"
      class="ztop"
      :title="'Reservación #' + reservationNumber"
      :visible.sync="editModalIsOpen"
      :before-open="prefillEditForm"
      :before-close="closeEditModal"
    >
      <div id="printMe">
        <div class="row">
          <div class="text-lg grid grid-cols-1 text-center ml-auto mr-auto">
            <h4>{{ firstName + " " + lastName }}</h4>
            <h4 class="text-muted mt-1">Room: {{ roomNumberDefault }}</h4>
          </div>
        </div>
        <div class="grid grid-cols-2">
          <div
            class="card card-animation-on-hover text-center col-6 ml-auto mr-auto"
          >
            <h4 class="mb-1 text-muted font-weight-700">User:</h4>
            <md-button type="warning" class="text-lg mt-0">{{
              email
            }}</md-button>
          </div>
          <div
            class="card card-animation-on-hover text-center col-6 ml-auto mr-auto"
          >
            <h4 class="mb-1 text-muted font-weight-700" @click="print">
              Pass:
            </h4>
            <md-button type="warning" class="text-lg mt-0">{{
              tempPassword
            }}</md-button>
          </div>
        </div>
        <div class="grid grid-cols-1">
          <el-divider
            ><h4 class="text-gray-800 text-2xl text-theme-primary">
              PAPCR.COM/QR
            </h4></el-divider
          >
          <vue-qrcode
            ref="magicLogin"
            class="ml-auto mr-auto"
            :scale="6"
            :value="magicLogin"
          />
        </div>
      </div>
      <el-divider class="mt-1 mb-1"></el-divider>
      <div class="ml-auto mr-auto grid grid-cols-1">
        <md-button
          v-print="'#printMe'"
          class="ml-auto mr-auto text-lg md-icon-button md-primary"
        >
          <md-icon>print</md-icon>
        </md-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Moment from "moment";
import { extendMoment } from "moment-range";
import { auth, db } from "@/firebase/config";

//Others
import Fuse from "fuse.js";

import { extend } from "vee-validate";
import {
  confirmed,
  email,
  max,
  max_value,
  min,
  min_value,
  numeric,
  required
} from "vee-validate/dist/rules";
import VueQrcode from "vue-qrcode";
import Tooltip from "@/views/Tooltip";

const moment = extendMoment(Moment);

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("numeric", numeric);
extend("min", min);
extend("min_value", min_value);
extend("max", max);
extend("max_value", max_value);
export default {
  components: {
    Tooltip,
    VueQrcode
  },
  data() {
    return {
      welcomeMessages: [
        "Bienvenid@s",
        "Welcome",
        "Wilkomen",
        "Willkommen",
        "Velkommen",
        "Powitanie",
        "Welkom",
        "歡迎",
        "欢迎光临",
        "Bienvenue",
        "ברוכים הבאים",
        "Tá fáilte romhaibh",
        "ようこそ",
        "Добродошли",
        "स्वागत ह"
      ],
      userID: null,
      editModalIsOpen: false,
      agency: "",
      agencies: [{}],
      paxMainRoom: null,
      paxExtraRoom: [],
      extraRooms: [],
      users: {},
      userType: "guest",
      roomNumber: "",
      roomNumberDefault: "",
      rooms: [{}],
      reservationNumber: "",
      country: "",
      tempPassword: "",
      email: "",
      phone: "",
      notes: "",
      firstName: "",
      lastName: "",
      subscribe: false,
      documentID: "",
      documentType: "",
      checkinDate: null,
      checkoutDate: null,
      roomReadyTime: null,
      disabledRooms: [{}],
      //Table
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 500],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["firstName", "email", "documentID", "reservationNumber"],
      tableColumns: [
        {
          prop: "reservationNumber",
          label: "# de reserva",
          minWidth: 150
        },
        {
          prop: "fullName",
          label: "Nombre",
          minWidth: 130
        },
        {
          prop: "roomNumber",
          label: "Room #",
          minWidth: 120
        },
        {
          prop: "extra_rooms",
          label: "Extra Rooms",
          minWidth: 150
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },

  computed: {
    magicLogin() {
      let self = this;
      let uName = self.email;
      let uPass = self.tempPassword;
      return (
        "https://papcr.com/#/login?username=" +
        uName +
        "&password=" +
        uPass +
        "&userIsCheckinIn=true"
      );
    },
    searchGuest() {
      let result = this.tableData.filter(
        data =>
          !this.searchQuery ||
          data.firstName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          data.lastName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          data.fullName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          data.reservationNumber
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          data.country.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          data.documentID
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          data.agency.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          data.phone.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          data.roomNumber
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          data.email
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
      );
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedDataAdmin() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    userEmail() {
      if (this.isUsername) {
        return this.randomUserName + "@rapiuhotels.com";
      } else {
        return this.email;
      }
    },
    userPassword() {
      switch (this.userType) {
        case "guest":
          return this.randomPassword;
        case "admin":
          return this.password;
        default:
          return this.password;
      }
    },
    randomUserName() {
      // return this.reservationNumber
      let randUser;
      return (randUser =
        this.firstName.substring(0, 3).toLowerCase() +
        this.lastName.substring(0, 2).toLowerCase() +
        moment().format("DD") +
        moment().format("M"));
    },
    randomPassword() {
      let randPass;
      return (randPass =
        this.firstName.substring(0, 3).toLowerCase() +
        moment().format("HH") +
        this.roomNumber);
    },
    paxExtraRoomsParsed() {
      let parsed = {};
      try {
        this.paxExtraRoom.forEach((pax, index) => {
          parsed[index] = {
            room: index,
            paxNumber: pax
          };
        });
      } catch (e) {
        this.$h.showError(null, e);
      }

      return parsed;
    }
  },
  watch: {
    checkinDate(value) {
      this.loadRoomData();
    },
    checkoutDate(value) {
      this.loadRoomData();
    },
    roomNumber(value, oldValue) {
      let roomsCopy = [{}];
      roomsCopy.splice(0, roomsCopy.length);
      this.rooms.forEach(item => {
        item.rooms = item.rooms.filter(function(obj) {
          return obj.number !== value;
        });
        if (oldValue !== "") {
          item.rooms.unshift({
            name: "Room " + oldValue,
            number: oldValue
          });
        }
        roomsCopy.push(item);
      });
      this.rooms.splice(0, this.rooms.length);
      this.rooms = roomsCopy;
    },
    userType(value) {
      let self = this;
      value === "guest" ? (this.newAdmin = false) : (this.newAdmin = true);
      // self.tableData = []
      self.tableData.splice(0, self.tableData.length);
      this.loadUsers();
    },
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
        // console.log(this.fuseSearch.search('Kevin'))
      }
      // console.log(result)
      this.searchedData = result;
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["firstName", "email", "lastName", "reservationNumber"],
      threshold: 0.3
    });
    this.$root.loading = false;
  },
  created() {
    moment().utcOffset("-06:00");
    let self = this;
    auth.onAuthStateChanged(userAuth => {
      // console.log(userAuth.uid)
      if (userAuth) {
        this.loadUsers();
        this.loadRoomData();
        this.$root.loading = false;
      }
    });
  },
  beforeDestroy() {
    this.$root.snapshots.roomData();
    this.$root.snapshots.reservationData();
  },

  methods: {
    setPage(page) {
      this.pagination.currentPage = page;
    },
    goHome() {
      this.$router.push("/");
    },
    print() {
      // this.$refs.easyPrint.print()
    },
    closeEditModal() {
      this.$root.loading = false;
      this.editModalIsOpen = false;
      this.emailChanged = false;
    },
    prefillEditForm(index, row) {
      this.firstName = row.firstName;
      this.lastName = row.lastName;
      this.reservationNumber = row.reservationNumber;
      this.roomNumberDefault = row.roomNumber;
      this.extraRooms = JSON.parse("[" + row.extra_rooms + "]");
      this.agency = row.agency;
      this.checkinDate = row.checkinDate.seconds * 1000;
      this.checkoutDate = row.checkoutDate.seconds * 1000;
      this.userID = row.id;
      this.tempPassword = row.tempPassword;
      this.email = row.email;
    },
    handleLike(index, row) {
      swal.fire({
        title: `You liked ${row.name}`,
        buttonsStyling: true,
        icon: "success",
        customClass: {
          confirmButton: "btn btn-success btn-fill"
        }
      });
    },
    handleEdit(index, row) {
      let self = this;
      this.$swal
        .fire({
          title: "Estás segur@?",
          text: `Mostrar credenciales de ${row.firstName +
            " " +
            row.lastName}?`,
          icon: "info",
          showCancelButton: true,
          customClass: {
            confirmButton:
              "button m-2 button--sm text-white bg-theme-1 align-top",
            cancelButton:
              "button m-2 button--sm text-white bg-theme-0 align-top"
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown"
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp"
          },
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: true
        })
        .then(result => {
          if (result.value) {
            this.editModalIsOpen = true;
            this.prefillEditForm(index, row);
          }
        });
    },
    async loadUsers() {
      let self = this;
      let now = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      this.$root.snapshots.reservationData = await db
        .collection(localStorage.getItem("hotel"))
        .doc("guest")
        .collection("reservations")
        .where("checkinIsDone", "==", false)
        .where("checkoutIsDone", "==", false)
        .where("noShow", "==", false)
        .where("cancelled", "==", false)
        .where("checkinDate", "==", now.toDate())
        .onSnapshot(docSnapshot => {
          let source = docSnapshot.metadata.fromCache
            ? "local cache"
            : "server";
          console.log("Data came from " + source);

          // self.tableData = [];
          self.tableData.splice(0, self.tableData.length);
          docSnapshot.forEach(doc => {
            let user = doc.data();
            // console.log(user)
            user.id = doc.id;
            // let checkinDate = new Date(user.checkinDate.seconds * 1000);
            // if (now.isSame(checkinDate)){
            if (!user.checkinIsDone) {
              self.tableData.push(user);
              //If user has multiple rooms, we need to show the array as a separated comma list
              // Sort room numbers ascending
              user.extra_rooms.sort(function(a, b) {
                return a - b;
              });
              user.extra_rooms = user.extra_rooms.join(", ");
            }
            // }
            this.$root.dataBus.totalCheckins = this.tableData.length;
          });
        });
    },
    totalPax(row) {
      // console.log(row)
      let mainPax = row.paxMainRoom;
      let extraPax = 0;
      if (!!row.paxMainRoom && !!row.paxExtraRoom) {
        Object.keys(row.paxExtraRoom).forEach(item => {
          extraPax = extraPax + row.paxExtraRoom[item];
        });
      }
      return mainPax + extraPax;
    },
    formattedDate(date) {
      if (date) {
        return moment(date.seconds * 1000).format("DD/MM/YY");
      }
    },
    loadRoomData() {
      //Load Main Rooms
      try {
        this.$root.snapshots.roomData = db
          .collection("rooms_" + localStorage.getItem("hotel"))
          .orderBy("index")
          .onSnapshot(doc => {
            let source = doc.metadata.fromCache ? "local cache" : "server";
            console.log("Data came from " + source);
            this.rooms.splice(0, this.rooms.length);
            doc.forEach(doc => {
              this.rooms.push(doc.data());
            });
          });
      } catch (e) {
        this.$h.showError(null, e);
      }
    }
  }
};
</script>
