<template>
  <div>
    <DarkModeSwitcher />
    <div class="container">
      <!-- BEGIN: Error Page -->
      <div
        class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
      >
        <div class="-intro-x lg:mr-20">
          <img
            alt="error-illustration"
            class="h-48 lg:h-auto"
            :src="require(`@/assets/images/error-illustration.svg`)"
          />
        </div>
        <div class="text-gray-500 mt-10 lg:mt-0">
          <div class="intro-x text-6xl font-medium mb-10">404</div>

          <div class="intro-x text-xl lg:text-2xl font-medium">
            Oops! de algún modo llegaste a una dimensión desconocida
          </div>
          <div class="intro-x text-lg mt-3">
            Que no cunda el pánico, te llevaremos de regreso sano y salvo
          </div>
          <md-button
            class="md-button md-button-spaced md-primary md-button-content md-raised intro-x mt-10"
            @click="goHome"
          >
            Haz click aquí
          </md-button>
        </div>
      </div>
      <!-- END: Error Page -->
    </div>
  </div>
</template>

<script>
import DarkModeSwitcher from "@/components/DarkModeSwitcher";

export default {
  components: {
    DarkModeSwitcher
  },
  mounted() {
    cash("body")
      .removeClass("app")
      .addClass("login");
  },
  methods: {
    goHome() {
      this.$router.replace("/");
    }
  }
};
</script>
