import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "vuetify/dist/vuetify.min.css";
Vue.use(Vuetify);

export default new Vuetify({
  // theme: { disable: true }
  theme: {
      options: {
          customProperties: true,
      },
      themes: {
          light: {
              background: '#1c1d2c',
          },
      },
      light: true,
  },
});
