const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "Dashboard",
        title: "Dashboard"
      },
      {
        icon: "UsersIcon",
        pageName: "",
        title: "Trabajos",
        subMenu: [
          {
            icon: "",
            pageName: "RegisterJobs",
            title: "Registrar Trabajos",
            ignore: true
          },
          {
            icon: "",
            pageName: "EditJobs",
            title: "Editar Trabajos",
            ignore: true
          },
        ]
      }
      // {
      //   icon: "SettingsIcon",
      //   role: "admin",
      //   pageName: "",
      //   title: "Configuración",
      //   subMenu: [
      //     {
      //       icon: "",
      //       pageName: "Dashboard",
      //       title: "Usuarios Administrativos",
      //       ignore: true
      //     }
      //   ]
      // }
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
