<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Ajustes</h2>
    </div>
    <div class="pos intro-y grid grid-cols-1 gap-5 mt-5">
      <!-- BEGIN: Ticket -->
      <div class="col-span-12 lg:col-span-4">
        <div class="intro-y pr-1">
          <div class="box p-2">
            <div class="pos__tabs nav-tabs justify-center flex">
              <a
                data-toggle="tab"
                data-target="#origin"
                href="javascript:;"
                class="flex-1 py-2 rounded-md text-center active"
                @click="selectedTool = 'origin'"
                >Orígenes y Tarifas</a
              >
              <a
                data-toggle="tab"
                data-target="#roomCapacity"
                href="javascript:;"
                class="flex-1 py-2 rounded-md text-center"
                @click="selectedTool = 'roomCapacity'"
                >Habitaciones</a
              >
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div id="origin" class="tab-content__pane active">
            <div>
              <div class="grid grid-cols-12 gap-6 mt-5">
                <div class="intro-y col-span-12 lg:col-span-5">
                  <!-- BEGIN: Inline Form -->
                  <div class="intro-y box mt-5">
                    <div
                      class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                    >
                      <h2 class="font-medium text-base mr-auto">Origenes</h2>
                    </div>
                    <div id="inline-form2" class="p-5">
                      <ValidationObserver v-slot="{ handleSubmit }">
                        <form
                          ref="registerForm"
                          @submit.prevent="handleSubmit(submit)"
                        >
                          <div class="mb-4">
                            <div class="grid grid-cols-2 gap-2">
                              <ValidationProvider
                                v-slot="{ passed, failed, errors }"
                                name="Origen"
                                rules="required|min:2"
                              >
                                <md-field class="mb-0">
                                  <label>Origen</label>
                                  <md-input
                                    v-model="originName"
                                    required
                                  ></md-input>
                                  <!--                                    <plus-circle-icon v-if="subOrigins.length" @click="addSubOrigin" class="cursor-pointer"></plus-circle-icon>-->
                                </md-field>
                                <span class="text-theme-danger mt-0">{{
                                  errors[0]
                                }}</span>
                              </ValidationProvider>
                              <ValidationProvider
                                v-if="!subOrigins.length"
                                v-slot="{ passed, failed, errors }"
                                name="Tarifa"
                                rules="required"
                              >
                                <md-field class="mb-0">
                                  <label>Tarifa</label>
                                  <span class="md-prefix">$</span>
                                  <md-input
                                    v-model="originRate"
                                    required
                                  ></md-input>
                                  <!--                                    <plus-circle-icon @click="addSubOrigin" class="cursor-pointer"></plus-circle-icon>-->
                                </md-field>
                                <span class="text-theme-danger mt-0">{{
                                  errors[0]
                                }}</span>
                              </ValidationProvider>
                            </div>
                            <div
                              v-for="(origin, index) in subOrigins"
                              class="ml-5 grid grid-cols-2 gap-2"
                            >
                              <ValidationProvider
                                v-slot="{ passed, failed, errors }"
                                :name="'Sub Origen-' + index"
                                rules="required|min:2"
                              >
                                <md-field class="mb-0">
                                  <label>Sub Origen</label>
                                  <md-input
                                    v-model="origin.name"
                                    required
                                  ></md-input>
                                </md-field>
                                <span class="text-theme-danger mt-0">{{
                                  errors[0]
                                }}</span>
                              </ValidationProvider>
                              <ValidationProvider
                                v-slot="{ passed, failed, errors }"
                                :name="'Rate-' + index"
                                rules="required"
                              >
                                <md-field class="mb-0">
                                  <label>Tarifa</label>
                                  <span class="md-prefix">$</span>
                                  <md-input
                                    v-model="origin.rate"
                                    required
                                  ></md-input>
                                  <minus-circle-icon
                                    class="cursor-pointer"
                                    @click="subOrigins.splice(index, 1)"
                                  ></minus-circle-icon>
                                </md-field>
                                <span class="text-theme-danger mt-0">{{
                                  errors[0]
                                }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <md-button
                            type="submit"
                            class="md-icon-button md-raised float-right md-primary"
                          >
                            <md-icon>save</md-icon>
                          </md-button>
                        </form>
                      </ValidationObserver>
                    </div>
                  </div>
                  <!-- END: Inline Form -->
                  <div class="intro-y box mt-10">
                    <div
                      class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                    >
                      <h2 class="font-medium text-base mr-auto">Inactivos</h2>
                    </div>
                    <md-table
                      v-model="searched"
                      class="mt-5"
                      md-sort="name"
                      md-sort-order="asc"
                      md-fixed-header
                    >
                      <md-table-toolbar>
                        <md-field md-clearable class="md-toolbar-section-end">
                          <md-input
                            v-model="search"
                            placeholder="Buscar por nombre..."
                            @input="searchOnTable"
                          />
                        </md-field>
                      </md-table-toolbar>
                      <md-table-empty-state
                        md-label="No se encontraron resultados"
                        :md-description="
                          `No se encontraron resultados que coincidan con '${search}' intenta algo diferente`
                        "
                      >
                      </md-table-empty-state>

                      <md-table-row
                        v-show="!item.active"
                        slot="md-table-row"
                        slot-scope="{ item }"
                      >
                        <md-table-cell md-label="Nombre" md-sort-by="name">{{
                          item.name
                        }}</md-table-cell>
                        <md-table-cell align="left" md-label="Restaurar"
                          ><check-circle-icon
                            class="cursor-pointer"
                            @click="restoreAgency(item)"
                          ></check-circle-icon
                        ></md-table-cell>
                      </md-table-row>
                    </md-table>
                  </div>
                </div>
                <div class="intro-y col-span-12 lg:col-span-7">
                  <div class="intro-y box mt-5">
                    <div
                      class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                    >
                      <h2 class="font-medium text-base mr-auto">Activos</h2>
                    </div>
                    <md-table
                      v-model="searched"
                      class="mt-5"
                      md-sort="name"
                      md-sort-order="asc"
                      md-fixed-header
                      @md-selected="onSelect"
                    >
                      <md-table-toolbar>
                        <md-field md-clearable class="md-toolbar-section-end">
                          <md-input
                            v-model="search"
                            placeholder="Buscar por nombre..."
                            @input="searchOnTable"
                          />
                        </md-field>
                      </md-table-toolbar>
                      <md-table-empty-state
                        md-label="No se encontraron resultados"
                        :md-description="
                          `No se encontraron resultados que coincidan con '${search}' intenta algo diferente`
                        "
                      >
                      </md-table-empty-state>
                      <md-table-row
                        v-show="item.active"
                        slot="md-table-row"
                        slot-scope="{ item }"
                        md-selectable="single"
                      >
                        <md-table-cell md-label="Nombre" md-sort-by="name">{{
                          item.name
                        }}</md-table-cell>
                        <md-table-cell align="center" md-label="Desactivar"
                          ><slash-icon
                            class=""
                            @click="deleteAgency(item)"
                          ></slash-icon
                        ></md-table-cell>
                      </md-table-row>
                    </md-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="roomCapacity" class="tab-content__pane">
            <div>
              <div class="grid grid-cols-12 gap-6 mt-5">
                <div class="intro-y col-span-12 lg:col-span-12">
                  <!-- BEGIN: Inline Form -->
                  <div class="intro-y box mt-5">
                    <div
                      class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
                    >
                      <h2 class="font-medium text-base mr-auto">
                        Capacidad de habitaciones
                      </h2>
                    </div>
                    <div id="inline-form" class="p-5">
                      <form
                        novalidate
                        class="md-layout"
                        @submit.prevent="validateUser"
                      >
                        <div class="">
                          <div
                            class="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-12 gap-2"
                          >
                            <md-field
                              v-for="(value, key, index) in roomCapacity"
                            >
                              <label>Hab #{{ key }}</label>
                              <md-input
                                v-model.number="roomCapacity[key]"
                                pattern="[0-9]"
                                type="phone"
                                @change="submit"
                              ></md-input>
                            </md-field>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <!-- END: Inline Form -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Ticket -->
    </div>
  </div>
</template>
<script>
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  numeric,
  min_value,
  min
} from "vee-validate/dist/rules";
extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("numeric", numeric);
extend("min", min);
extend("min_value", min_value);

const toLower = text => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter(
      item => toLower(item.name).includes(toLower(term)) && item.active
    );
  }

  return items;
};

import { db, auth } from "@/firebase/config";
export default {
  data() {
    return {
      updating: false,
      subOrigins: [],
      edit: [],
      agenciesSelection: [],
      originName: null,
      originRate: null,
      originCode: null,
      disableButton: false,
      editModalIsOpen: false,
      selectedTool: "origin",
      tableData: [],
      searched: [],
      search: null,
      searchedData: [],
      fuseSearch: null,
      roomCapacity: {}
    };
  },
  watch: {
    originName(value) {
      if (value === null || value === "") {
        this.updating = false;
      }
    },
    tableData(value) {
      value.forEach(val => {
        this.edit[val.code] = false;
      });
      // console.log(this.edit)
    },
    selectedTool(value) {
      let self = this;
      switch (value) {
        case "origin":
          //
          break;
        case "roomCapacity":
          //
          break;
        default:
        //
      }
      value === "agencies" ? (this.isAgency = true) : (this.agencies = false);
      // self.tableData = []
      self.tableData.splice(0, self.tableData.length);
      this.loadAgencies();
    }
  },
  mounted() {
    this.uid = auth.currentUser.uid;
  },
  created() {
    moment().utcOffset("-06:00");
    let self = this;
    auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
        this.loadAgencies();
        this.loadRoomCapacity();
        this.$root.loading = false;
      }
    });
  },
  beforeDestroy() {
    this.$root.snapshots.agencies();
    this.$root.snapshots.roomCapacity();
  },
  methods: {
    magicAction() {
      this.tableData.forEach(val => {
        val.active = true;
      });
      db.collection("misc_data")
        .doc("origin")
        .update({
          [localStorage.getItem("hotel")]: this.tableData
        });
    },
    onSelect(item) {
      if (item) {
        this.originName = item.name;
        this.originRate = item.rate || 0;
        this.originCode = item.code;
        this.subOrigins = item.subOrigin || [];
        this.updating = true;
      }
    },
    searchOnTable() {
      this.searched = searchByName(this.tableData, this.search);
    },
    addSubOrigin() {
      let lastCode = Math.max(0, ...this.subOrigins.map(o => o.code)) + 1;
      let mainOriginLastCode = Math.max(0, ...this.tableData.map(o => o.code)) + 1;
      this.subOrigins.push({
        name: "",
        rate: null,
        code: lastCode,
        subCode: (this.originCode || mainOriginLastCode) + "-" + lastCode
      });
      console.log(this.subOrigins);
    },
    deleteAgency(agency) {
      let self = this;
      this.$swal
        .fire({
          title: "Estas segur@?",
          text:
            `Estás a punto de eliminar a ` +
            agency.name +
            " | Este cambio es irreversible y afectará todas las reservas anteriores asignadas a este origen, ",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton:
              "button m-2 button--sm text-white bg-theme-1 align-top",
            cancelButton:
              "button m-2 button--sm text-white bg-theme-0 align-top"
          },
          confirmButtonText: "Si, sé lo que estoy haciendo!",
          buttonsStyling: true
        })
        .then(result => {
          let loader = this.$loading.show();
          if (result.value) {
            // this.tableData = this.tableData.filter(function(obj) {return obj.code !== agency.code}) //This removes the item from the array
            let index = this.tableData.findIndex(
              item => item.code === agency.code
            ); // to find the index based on a property value
            this.tableData[index].active = false; //This sets the origin as not active, so it won't show up in the origin table and in the new reservation form.
            try {
              db.collection("misc_data")
                .doc("origin")
                .update({
                  [localStorage.getItem("hotel")]: this.tableData
                })
                .then(function() {
                  self.$h.toast(
                    "error",
                    agency.name + " se ha eliminado satisfactoriamente",
                    "top"
                  );
                  self.clearInputs();
                  loader.hide();
                });
            } catch (e) {
              loader.hide();
              this.clearInputs();
              self.$h.showError(null, e);
            }
          }
          loader.hide();
        });
    },
    restoreAgency(agency) {
      let self = this;
      this.$swal
        .fire({
          title: "Estas segur@?",
          text: `Estás a punto de restaurar a ` + agency.name,
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton:
              "button m-2 button--sm text-white bg-theme-1 align-top",
            cancelButton:
              "button m-2 button--sm text-white bg-theme-0 align-top"
          },
          confirmButtonText: "Si, sé lo que estoy haciendo!",
          buttonsStyling: true
        })
        .then(result => {
          let loader = this.$loading.show();
          if (result.value) {
            // this.tableData = this.tableData.filter(function(obj) {return obj.code !== agency.code}) //This removes the item from the array
            let index = this.tableData.findIndex(
              item => item.code === agency.code
            ); // to find the index based on a property value
            this.tableData[index].active = true; //This sets the origin as not active, so it won't show up in the origin table and in the new reservation form.
            try {
              db.collection("misc_data")
                .doc("origin")
                .update({
                  [localStorage.getItem("hotel")]: this.tableData
                })
                .then(function() {
                  self.$h.toast(
                    "success",
                    agency.name + " se ha restaurado satisfactoriamente",
                    "top"
                  );
                  self.clearInputs();
                  loader.hide();
                });
            } catch (e) {
              loader.hide();
              this.clearInputs();
              self.$h.showError(null, e);
            }
          }
          loader.hide();
        });
    },
    clearInputs() {
      this.originName = null;
      this.originRate = null;
      this.originCode = null;
      this.subOrigins = [];
    },
    async submit() {
      let self = this;
      let loader = this.$loading.show();
      try {
        switch (this.selectedTool) {
          case "origin":
            let boolEval = [];
            if (this.originName.trim() !== "") {
              if (!this.updating) {
                this.tableData.forEach(function(val, index) {
                  boolEval[index] =
                    val.name.toLowerCase().trim() ===
                    self.originName.toLowerCase().trim();
                });
                if (boolEval.includes(true)) {
                  this.clearInputs();
                  loader.hide();
                  this.$swal({
                    title: "Duplicado!",
                    text:
                      "Este dato ya existe!, no te preocupes no lo guardaremos para evitar confusiones :)",
                    icon: "error",
                    confirmButtonText: "Entiendo"
                  });
                } else {
                  let lastCode =
                    Math.max(0, ...this.tableData.map(o => o.code)) + 1;
                  this.tableData.push({
                    active: true,
                    name: this.originName.trim(),
                    code: lastCode,
                    rate: this.originRate,
                    subOrigin: this.subOrigins
                  });
                }
              } else {
                let index = this.tableData.findIndex(
                  item => item.code === this.originCode
                );
                this.tableData[index] = {
                  active: true,
                  name: this.originName.trim(),
                  rate: this.originRate,
                  subOrigin: this.subOrigins
                };
              }
              //save agency
              try {
                await db
                  .collection("misc_data")
                  .doc("origin")
                  .update({
                    [localStorage.getItem("hotel")]: this.tableData
                  })
                  .then(function() {
                    self.clearInputs();
                    self.$h.toast(
                      "success",
                      "Se modificó el origen correctamente",
                      "top"
                    );
                    loader.hide();
                  });
                loader.hide();
              } catch (e) {
                loader.hide();
                await self.$h.showError(null, e);
              }
            }
            break;
          case "roomCapacity":
            try {
              await db
                .collection("misc_data")
                .doc("max_room_capacity")
                .update({
                  [localStorage.getItem("hotel")]: this.roomCapacity
                })
                .then(function() {
                  // self.agencyName = "";
                  // self.$refs.agencyName.focus();
                  loader.hide();
                  self.$h.toast(
                    "success",
                    "Se modificó el registro correctamente",
                    "top",
                    10000
                  );
                });
              loader.hide();
            } catch (e) {
              loader.hide();
              await self.$h.showError(null, e);
            }
            break;
          default:
            loader.hide();
          //
        }
      } catch (e) {
        loader.hide();
        await self.$h.showError(null, e);
      }
    },
    compare(a, b) {
      // console.log(a, b)
      // Use toUpperCase() to ignore character casing
      const agencyA = a.name.toUpperCase();
      const agencyB = b.name.toUpperCase();

      let comparison = 0;
      if (agencyA > agencyB) {
        comparison = 1;
      } else if (agencyA < agencyB) {
        comparison = -1;
      }
      return comparison;
    },
    loadAgencies() {
      let self = this;
      // this.tableData = [];
      self.tableData.splice(0, self.tableData.length);
      self.searched.splice(0, self.searched.length);
      //Load availability
      try {
        this.$root.snapshots.agencies = db
          .collection("misc_data")
          .doc("origin")
          .onSnapshot(doc => {
            let source = doc.metadata.fromCache ? "local cache" : "server";
            console.log("Data came from " + source);
            if (doc.data()[localStorage.getItem("hotel")]) {
              this.tableData = doc
                .data()
                [localStorage.getItem("hotel")].sort(this.compare);
              this.searched = this.tableData;
            }
            // console.log(this.tableData)
          });
      } catch (e) {
        console.log(e);
      }
    },
    loadRoomCapacity() {
      try {
        this.$root.snapshots.roomCapacity = db
          .collection("misc_data")
          .doc("max_room_capacity")
          .onSnapshot(doc => {
            let source = doc.metadata.fromCache ? "local cache" : "server";
            console.log("Data came from " + source);
            if (doc.data()[localStorage.getItem("hotel")]) {
              this.roomCapacity = doc.data()[localStorage.getItem("hotel")];
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
