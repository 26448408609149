var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"pos intro-y grid grid-cols-1 gap-5 mt-5"},[_c('div',{staticClass:"grid grid-cols-12 gap-6 mt-5"},[_c('div',{staticClass:"intro-y col-span-12 lg:col-span-12"},[(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"disable-pagination":"","search":_vm.search,"items":_vm.tableData,"headers":_vm.tableHeaders,"item-key":"uid"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(!!item.notes || !!item.inNotes || item.guestLoyaltyLevel > 0)?_c('v-tooltip',{attrs:{"internal-activator":"","color":"#222222","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer animate",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-access-point")]),_vm._v(" - ")]}}],null,true)},[_c('span',{staticClass:"m-2"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!item.notes),expression:"!!item.notes"}]},[_c('h4',{staticClass:"text-theme-warning font-weight-bold"},[_vm._v(" Notas del cliente ")]),_c('h5',{staticClass:"text-white"},[_vm._v(_vm._s(item.notes))]),_c('span',{staticClass:"m-2"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.guestLoyaltyLevel > 0),expression:"item.guestLoyaltyLevel > 0"}]},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.loyaltyLevel(item.guestLoyaltyLevel))+" ")])],1)]):_vm._e(),_c('v-tooltip',{attrs:{"internal-activator":"","color":"#222222","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"cursor-pointer"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("truncate")(item.id,10))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.id))])])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedDate(item.date))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone.slice(0, 4) + '-' + item.phone.slice(4))+" ")]}},{key:"item.zone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.decodeZone(item.zone))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1 white--text",attrs:{"disabled":item.jobDone,"color":"green","x-small":"","fab":""},on:{"click":function($event){return _vm.doJobDone(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-up ")])],1)]}}],null,true)},[_c('span',[_vm._v("Completar trabajo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1 white--text",attrs:{"color":"blue","x-small":"","fab":""},on:{"click":function($event){return _vm.handleEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-book-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])]}}],null,false,1648962308)}):_c('div',[_c('v-skeleton-loader',{attrs:{"type":"table: table-heading, table-thead, table-tbody"}})],1)],1)])]),_c('v-dialog',{attrs:{"fullscreen":"","content-class":"box p-10 z-top","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.editModalIsOpen),callback:function ($$v) {_vm.editModalIsOpen=$$v},expression:"editModalIsOpen"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"registerForm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateUser)}}},[_c('div',{staticClass:"intro-y col-span-12 lg:col-span-12"},[_c('div',{staticClass:"intro-y box mt-5"},[_c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v("Fechas")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.editModalIsOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"lg:flex intro-y px-5"},[_c('div',{staticClass:"relative ml-auto mr-auto text-gray-700 dark:text-gray-300"},[_c('van-sticky',[_c('div',{staticClass:"intro-y nl items-center mt-5 h-10"},[_c('div',{staticClass:"flex items-center ml-auto mr-auto text-centers"},[_c('calendar-icon',{staticClass:"mr-1"}),_c('vc-date-picker',{staticClass:"flex-grow dateCenter",attrs:{"placement":"bottom","popover-align":"center","locale":'es',"title-position":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var togglePopover = ref.togglePopover;
return [_c('v-text-field',{staticClass:"dateCenter mt-1",attrs:{"dense":"","outlined":"","background-color":"#ffffff","readonly":"","value":inputValue,"hide-details":"auto"},on:{"focus":function($event){return togglePopover({ placement: 'auto-start' })}}})]}}],null,true),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('calendar-icon',{staticClass:"ml-1"})],1)])]),_c('el-divider')],1)]),_c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v("Datos del huésped")])]),_c('div',{staticClass:"intro-y items-center grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 px-5 mt-5 gap-5"},[_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{staticClass:"mb-0 intro-y"},[_c('label',[_vm._v("Nombre Completo")]),_c('md-input',{attrs:{"required":""},on:{"blur":_vm.splitFullName},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})],1),_c('span',{staticClass:"text-theme-danger mt-0"},[_vm._v(_vm._s(errors.length ? "El nombre es necesario y debe contener mínimo 3 letras" : ""))])]}}],null,true)})],1),_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Teléfono","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{staticClass:"mb-0 intro-y"},[_c('label',[_vm._v("Teléfono")]),_c('md-input',{model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('span',{staticClass:"text-theme-danger mt-0"},[_vm._v(_vm._s(errors.length ? "El el número de teléfono debe contener mínimo 4 caracteres" : ""))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v(" Notas o detalles internos ")])]),_c('div',{staticClass:"grid md:grid-cols-1 sm:grid-cols-1 grid-cols-1 intro-y items-center px-5 pb-5 mb-5 mt-5 gap-5"},[_c('div',{staticClass:"items-center"},[_c('ValidationProvider',{attrs:{"name":"Notas","rules":"min:3|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v("Notas internas")]),_c('md-textarea',{attrs:{"md-autogrow":"","md-counter":"200"},model:{value:(_vm.inNotes),callback:function ($$v) {_vm.inNotes=$$v},expression:"inNotes"}})],1),_c('span',{staticClass:"text-theme-danger mt-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"},[_c('md-button',{staticClass:"ml-auto mr-auto md-button-spaced md-raised md-primary",attrs:{"type":"submit"}},[_vm._v(" Actualizar ")])],1)])])])]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro-y flex flex-col sm:flex-row items-center mt-8"},[_c('h2',{staticClass:"text-lg font-medium mr-auto"},[_vm._v("Editar trabajos")])])}]

export { render, staticRenderFns }