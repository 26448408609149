<template>
  <div>
    <!--    <DarkModeSwitcher />-->
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="#" class="-intro-x items-center pt-5">
            <span class="text-white text-lg">
              RapiCR<span class="font-medium"> Admin</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              alt="Rapi CR"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-3xl leading-tight mt-10"
            >
              Bienvenid@ a RapiCR
            </div>
            <div class="-intro-x mt-5 text-lg text-white dark:text-gray-500">
              Una aplicación para dominarlas a todas
            </div>
            <div
              style="bottom: 0!important; position: fixed; left: 0!important;"
              class="-intro-x mb-4 ml-10 text-small text-xs text-white"
            >
              Made with ❤ by Josue Orozco A.
            </div>
            <div style="top: 0!important; position: fixed; right: 0!important;" class="intro-x mt-4 mr-10 text-small text-white">
              Versión: 1.0.1 - 2021
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="box xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
              Inicia Sesión
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              Inicia sesión con tus credenciales, y recuerda nunca compartirlas
              con nadie más.
            </div>
            <form @submit.prevent="submit">
              <div class="intro-x mt-8">
                <input
                  v-model="email"
                  type="text"
                  class="mx-auto intro-x login__input input input--lg border border-gray-300 block"
                  placeholder="Email / Usuario"
                />
                <input
                  v-model="password"
                  type="password"
                  class="mx-auto intro-x login__input input input--lg border border-gray-300 block mt-4"
                  placeholder="Contraseña"
                />
              </div>
              <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                <button
                  native-type="submit"
                  class="button button--lg w-full xl:w-32 text-white bg-theme-0 xl:mr-3 align-top"
                >
                  Acceder
                </button>
              </div>
            </form>
            <div
              class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
            >
              Al iniciar sesión aceptas nuestros <br />
              <a class="text-theme-1 dark:text-theme-10" href=""
                >Términos y condiciones</a
              >
              &
              <a class="text-theme-1 dark:text-theme-10" href=""
                >Politicas de privacidad</a
              >
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import cash from "cash-dom";
import { db, auth } from "@/firebase/config";
import Swal from "sweetalert2";
import DarkModeSwitcher from "@/components/DarkModeSwitcher";
export default {
  components: {
    DarkModeSwitcher
  },
  data() {
    return {
      email: "",
      password: "",
      showHotelSelector: false
    };
  },
  computed: {
    // loggedIn(){
    //   return this.$store.state.main.isLoggedIn
    // }
  },
  watch: {
    // loggedIn(val){
    //   if (val){
    //     this.prepare();
    //   }
    // }
  },
  mounted() {
    // cash("body")
    //   .removeClass("app")
    //   .addClass("login");
  },
  methods: {
    async signOut() {
      try {
        await auth.signOut().then(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.$h.hideLoader();
          this.$router.go(0);
        });
      } catch (e) {
        await this.$h.showError(null, e);
      }
    },
    getEmail(email) {
      let checkEmail = this.email.split("@")[1];
      if (checkEmail === undefined || checkEmail === "") {
        return email.trim() + "@rapiadmin.com";
      } else {
        return email.trim();
      }
    },
    async submit() {
      this.$h.showLoader();
      let self = this;
      await auth
        .signInWithEmailAndPassword(this.getEmail(this.email), this.password)
        .then(() => {
          this.redirect();
        })
        .catch(e => {
          // this.$h.showError(null, e);
          this.$h.hideLoader();
          switch (e.code) {
            case "auth/wrong-password":
              this.$swal
                .fire({
                  title: "Contraseña Incorrecta",
                  text: "La contraseña que has ingresado no es correcta",
                  icon: "error",
                  confirmButtonText: "Entiendo, volveré a intentar",
                  showClass: {
                    popup: "animate__animated animate__backInDown"
                  },
                  hideClass: {
                    popup: "animate__animated animate__backOutDown"
                  }
                })
                .then(result => {
                  if (result.value) {
                    this.signOut();
                  }
                });
              break;
            case "auth/user-not-found":
              this.$swal.fire({
                title: "Hola extraño!",
                text: "No reconocemos tu usuario, revisa que esté bien escrito",
                icon: "error",
                confirmButtonText: "Entiendo, volveré a intentar",
                showClass: {
                  popup: "animate__animated animate__backInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__backOutDown"
                }
              });
              break;
            case "auth/user-disabled":
              this.$swal.fire({
                title: "Oups!",
                text:
                  "Parece que tu usuario ha sido desactivado, consulta con un administrador",
                icon: "error",
                confirmButtonText: "Entiendo",
                showClass: {
                  popup: "animate__animated animate__backInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__backOutDown"
                }
              });
              break;
            default:
              this.$swal.fire({
                title: "Oups",
                text: "El siguiente error se presentó:" + " => " + e,
                icon: "error",
                confirmButtonText: "Comprendo",
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
          }
        });
    },
    redirect() {
      this.$h.hideLoader();
      this.$router.replace({ name: "Dashboard" });
    },
  }
};
</script>
<style scoped>
.login{
  background-color: transparent!important;
}
</style>
