import * as types from "./mutation-types";

const state = () => {
  return {
    selectedDate: null,
    darkMode: false,
    isLoggedIn: false,
    selectedModule: null,
    userData: {
      createdAt: null,
      createdBy: null,
      email: "",
      firstName: "",
      fullName: "",
      hotel: "",
      isActive: false,
      lastName: "",
      last_ip: null,
      last_seen: null,
      role: null,
      roles: {},
      uid: null
    }
  };
};

// getters
const getters = {
  darkMode: state => state.darkMode,
  userData: state => state.userData,
  isLoggedIn: state => state.isLoggedIn,
  hotel: state => state.userData.hotel,
  selectedModule: state => state.selectedModule,
  selectedDate: state => state.selectedDate
};

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode });
  },
  setUserData({ commit }, userData) {
    commit(types.SET_USER_DATA, { userData });
  },
  setIsLoggedIn({ commit }, isLoggedIn) {
    commit(types.SET_IS_LOGGED_IN, { isLoggedIn });
  },
  setHotel({ commit }, hotel) {
    commit(types.SET_HOTEL, { hotel });
  },
  setSelectedModule({ commit }, selectedModule) {
    commit(types.SET_SELECTED_MODULE, { selectedModule });
  },
  setSelectedDate({ commit }, selectedDate) {
    commit(types.SET_SELECTED_DATE, { selectedDate });
  }
};

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode;
  },
  [types.SET_USER_DATA](state, { userData }) {
    state.userData = userData;
  },
  [types.SET_IS_LOGGED_IN](state, { isLoggedIn }) {
    state.isLoggedIn = isLoggedIn;
  },
  [types.SET_HOTEL](state, { hotel }) {
    state.userData.hotel = hotel;
  },
  [types.SET_SELECTED_MODULE](state, { selectedModule }) {
    state.selectedModule = selectedModule;
  },
  [types.SET_SELECTED_DATE](state, { selectedDate }) {
    state.selectedDate = selectedDate;
  }
};
// const initialStateCopy = JSON.parse(JSON.stringify(state))
// export function resetState() {
//   store.replaceState(initialStateCopy)
// }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
