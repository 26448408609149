import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import "./global-components";
import "./utils";
import "./libs";
//Router
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import router from "./router/router";
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

//Vant
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

//Sweet Alert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

// Midone Theme
import "./assets/sass/app.scss";

Vue.config.productionTip = false;

//firebase
import { auth } from "./firebase/config";

let app;

import Moment from "moment";
import { extendMoment } from "moment-range";
import vuetify from "./plugins/vuetify";
const moment = extendMoment(Moment);

import VCalendar from "v-calendar";
import "./registerServiceWorker";

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
});

auth.onAuthStateChanged(() => {
  // console.log(user);
  if (!app) {
    app = new Vue({
      el: "#app",

      data() {
        return {
          activeMobileMenu: false,
          uid: null,
          loading: false,
          isOffline: false,
          userData: [],
          snapshots: {
            rooms: null,
            availability: null,
            zones: null,
            blocked: null,
            reservationData: null,
            roomCapacity: null,
            roomData: null,
            jobs: null,
            inHouse: null,
            checkouts: null,
            cancelled: null,
            noShow: null
          },
          dataBus: {
            totalCheckins: 0,
            totalCheckouts: 0,
            totalAdultsInHouse: 0,
            totalTeenagersInHouse: 0,
            totalKidsInHouse: 0,
            totalInfantsInHouse: 0,
            totalRoomsInHouse: 0,
            totalPaxInHouse: 0,
            checkoutsLeft: 0,
            paxInHouse: 0,
            roomsInHouse: 0,
            dashboardDate: moment()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .toDate(),
            checkinsTable: [],
            checkoutsTable: [],
            inHouseTable: [],
            noShowTable: [],
            cancelledTable: [],
            agencies: []
          },
          dateHasCheckins: false,
          dateHasCheckouts: false,
          dateHasInHouse: false,
          dateHasCancelledReservations: false,
          dateHasNoShowReservations: false
        };
      },

      watch: {
        "dataBus.totalCheckins": function() {
          // let now = moment();
          // let checkinTime = moment(this.dataBus.dashboardDate).set({
          //   hour: 20,
          //   minute: 50,
          //   second: 0,
          //   millisecond: 0
          // });
          // let checkinTimer = checkinTime.diff(now);
          // this.alertAboutCheckins(checkinTimer);
        },
        "dataBus.checkoutsLeft": function() {
          // let now = moment();
          // let checkoutTime = moment(this.dataBus.dashboardDate).set({
          //   hour: 14,
          //   minute: 0,
          //   second: 0,
          //   millisecond: 0
          // });
          // let checkoutTimer = checkoutTime.diff(now);
          // this.alertAboutCheckouts(checkoutTimer);
        }
      },

      created() {
        moment().utcOffset("-06:00");
        // Prevent blank screen in Electron builds
        // this.$router.push('/')
      },

      methods: {
        alertAboutCheckouts(timer) {
          if (moment(this.dataBus.dashboardDate).isSameOrBefore(moment())) {
            if (this.dataBus.checkoutsLeft > 0) {
              setTimeout(() => {
                this.$h.toast(
                  "error",
                  "Hay CHECKOUTS PENDIENTES de marcar en esta fecha: " +
                    moment(this.dataBus.dashboardDate).format("DD/MM/YYYY") +
                    " por favor no olvides revisarlos y marcar su salida",
                  "bottom",
                  880 * 1000,
                  true,
                  true
                );
              }, timer);
            } else {
              this.$toast.clear();
            }
          }
        },
        alertAboutCheckins(timer) {
          if (moment(this.dataBus.dashboardDate).isSameOrBefore(moment())) {
            if (this.dataBus.totalCheckins > 0) {
              setTimeout(() => {
                this.$h.toast(
                  "error",
                  "Hay CHECKINS PENDIENTES de marcar en esta fecha: " +
                    moment(this.dataBus.dashboardDate).format("DD/MM/YYYY") +
                    " por favor no olvides revisarlos e ingresarlos o anularlos si fuera necesario.",
                  "top",
                  880 * 1000,
                  true,
                  true
                );
              }, timer);
            } else {
              this.$toast.clear();
            }
          }
        }
      },

      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }
});

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount("#app");
