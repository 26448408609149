<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Estado general de habitaciones
      </h2>
    </div>
    <div class="pos ml-auto mr-auto intro-y grid grid-cols-1 gap-5 mt-5">
      <!-- BEGIN: Item List -->
      <div class="intro-y col-span-12 lg:col-span-8">
        <div class="lg:flex intro-y">
          <div
            class="relative ml-auto mr-auto text-gray-700 dark:text-gray-300"
          >
            <van-sticky>
              <div class="intro-y nl items-center mt-5 h-10">
                <div class="flex items-center ml-auto mr-auto text-centers">
                  <calendar-icon class="mr-1"></calendar-icon>
                  <el-date-picker
                    v-model="date"
                    :editable="false"
                    range-separator="-"
                    format="dd/MM/yyyy"
                    prefix-icon="fa fa-calendars"
                    align-="right"
                    popper-class="ztopDateRangePicker"
                    :clearable="false"
                    class="col-4 mr-auto ml-auto"
                    type="daterange"
                    start-placeholder="Desde"
                    end-placeholder="Hasta"
                  >
                  </el-date-picker>
                  <calendar-icon class="ml-1"></calendar-icon>
                </div>
              </div>
            </van-sticky>
            <el-divider></el-divider>
          </div>
        </div>
        <div class="grid grid-cols-12 md:ml-20 md:mr-20 gap-6 mt-5">
          <div
            v-for="stat in statsCards"
            :key="stat.index"
            class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y p-0"
          >
            <div class="report-box zoom-in">
              <div class="box">
                <div
                  class="text-2xl grid grid-cols-2 pl-3 pt-3 font-bold leading-8 mt-6"
                >
                  {{ getTotalsStrings(stat.title) }}
                  <li
                    :class="
                      stat.icon +
                        ' text-xl text-right pr-3 pt-1 pt-w ' +
                        stat.color
                    "
                  ></li>
                </div>
                <div class="text-base pl-3 text-gray-600 mt-1 pb-2">
                  {{ stat.subTitle }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="grid md:grid-cols-3 sm:grid-cols-2 box">
          <div
            v-for="group in rooms"
            class="grid grid-cols-12 ml-auto mr-auto gap-3 mt-5 zoom-in p-5"
          >
            <div class="col-span-12">
              <h4 class="text-lg text-gray-700">{{ group.roomTypeName }}</h4>
            </div>
            <div
              v-for="room in group.rooms"
              :class="
                'col-span-4 xs:col-span-3 md:col-span-2 sm:col-span-3 xxl:col-span-r box w-10 h-10 cursor-pointer ' +
                  roomStatus(
                    !!disabledRooms[room.number],
                    !!blockedList[room.number],
                    blockedList,
                    room.number
                  )
              "
              @click="actionRoom(room.number, !!disabledRooms[room.number])"
            >
              <h4 class="font-medium text-white pt-2 text-center text-xl">
                {{ room.number }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Item List -->
    </div>
    <sweet-modal
      ref="actionRoom"
      style="z-index: 1000!important"
      enable-mobile-fullscreen
      overlay-theme="dark"
      width="70%"
    >
      <h3 class="text-center text-muted">Habitación {{ tempRoomData }}</h3>
      <div class="row">
        <div class="grid grid-cols-1 ml-auto mr-auto text-center">
          <el-date-picker
            v-model="date"
            style="z-index: 999999999999999999999999999999999999!important"
            :editable="false"
            range-separator="-"
            format="dd/MM/yyyy"
            prefix-icon="fa fa-calendars"
            :clearable="false"
            size="large"
            popper-class="ztopDateRangePicker"
            class="grid grid-cols-1 text-center ml-auto mr-auto"
            type="daterange"
            start-placeholder="Start Date"
            end-placeholder="End Date"
          >
          </el-date-picker>
        </div>
        <!--        <button class="button button&#45;&#45;sm bg-theme-primary text-white mt-3" type="info" @click="doBlockRoom()">Crear Reservación</button>-->
      </div>
      <el-divider></el-divider>
      <button
        v-if="!showOccupiedTable"
        class="button button--sm bg-theme-info text-white"
        type="info"
        @click="doBlockRoom()"
      >
        Bloquear
      </button>
      <button
        v-if="!showOccupiedTable && !showBlockedTable"
        class="button button--sm bg-theme-primary ml-10 text-white"
        type="info"
        @click="reserve()"
      >
        Reservar
      </button>
      <transition name="slide">
        <el-divider v-show="showBlockedTable"></el-divider>
      </transition>
      <transition name="slide">
        <h4 v-show="showBlockedTable" class="text-warning font-weight-700">
          Bloqueos
        </h4>
      </transition>
      <transition name="slide">
        <el-table
          v-show="showBlockedTable"
          stripe
          :data="filterBlocked(blocked)"
        >
          <el-table-column
            sortable
            show-overflow-tooltip
            label="# Hab."
            prop="room"
          >
          </el-table-column>
          <el-table-column sortable show-overflow-tooltip label="Fechas">
            <template slot-scope="scope">
              {{ parseDate(scope.row.fromDate) }} -
              {{ parseDate(scope.row.toDate) }}
            </template>
          </el-table-column>
          <el-table-column
            sortable
            show-overflow-tooltip
            label="Motivo de bloqueo"
          >
            <template slot-scope="scope">
              {{ scope.row.reason || "N/A" }}
            </template>
          </el-table-column>
          <el-table-column label="Acción" align="center">
            <template slot-scope="scope">
              <button
                type="warning"
                class="button button--sm bg-theme-secondary text-white"
                label="Liberar"
                border
                @click="doUnBlockRoom(scope.row)"
              >
                Liberar
              </button>
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <transition name="slide">
        <el-divider v-show="showOccupiedTable"></el-divider>
      </transition>
      <transition name="slide">
        <h4 v-show="showOccupiedTable" class="text-warning font-weight-700">
          Reservaciones
        </h4>
      </transition>
      <transition name="slide">
        <el-table
          v-show="showOccupiedTable"
          stripe
          :data="filterOccupied(tableData)"
          type="info"
        >
          <el-table-column
            sortable
            show-overflow-tooltip
            label="# Hab."
            prop="roomNumber"
          >
          </el-table-column>
          <el-table-column sortable show-overflow-tooltip label="Nombre">
            <template slot-scope="scope">
              {{ scope.row.fullName }}
            </template>
          </el-table-column>
          <el-table-column sortable show-overflow-tooltip label="# Reserva">
            <template slot-scope="scope">
              {{ scope.row.reservationNumber }}
            </template>
          </el-table-column>
          <el-table-column sortable show-overflow-tooltip label="Checkin">
            <template slot-scope="scope">
              {{ parseDate(scope.row.checkinDate) }}
            </template>
          </el-table-column>
          <el-table-column sortable show-overflow-tooltip label="Checkout">
            <template slot-scope="scope">
              {{ parseDate(scope.row.checkoutDate) }}
            </template>
          </el-table-column>
          <el-table-column sortable show-overflow-tooltip label="Extra Rooms">
            <template slot-scope="scope">
              {{ scope.row.extra_rooms || "N/A" }}
            </template>
          </el-table-column>
        </el-table>
      </transition>
    </sweet-modal>
  </div>
</template>
<script>
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
//Firebase
import { auth, db } from "@/firebase/config";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      showOccupiedTable: false,
      showBlockedTable: false,
      tempRoomData: null,
      tableData: [],
      modalDate: [
        moment()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate(),
        moment()
          .add(1, "days")
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate()
      ],
      date: [
        moment()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate(),
        moment()
          .add(1, "days")
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate()
      ],
      rooms: [{}],
      disabledRooms: [{}],
      blockedList: [],
      blocked: [],
      freeRooms: 0,
      busyRooms: 0,
      blockedRooms: 0,
      dirtyRooms: 0,
      statsCards: [
        {
          title: 1,
          subTitle: "Habs. Libres",
          color: "text-theme-secondary",
          type: "success",
          icon: "fa fa-check",
          footerStart: "Pendientes: ",
          footerEnd: ""
        },
        {
          title: 2,
          subTitle: "Habs. Ocupadas",
          color: "text-theme-primary",
          type: "warning",
          icon: "fa fa-house-user",
          footerStart: "Pendientes: ",
          footerEnd: ""
        },
        {
          title: 3,
          subTitle: "Habs. Bloqueadas",
          color: "text-theme-info",
          type: "primary",
          icon: "fa fa-lock",
          footerStart: "Pronóstico:  ",
          footerEnd: ""
        },
        {
          title: 4,
          subTitle: "Habs. en limpieza",
          color: "text-theme-brown",
          type: "info",
          icon: "fa fa-broom",
          footerStart: "Pronóstico: ",
          footerEnd: ""
        }
      ]
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return [
        { name: "Accounts", icon: "tim-icons icon-single-02" },
        {
          name: "Purchases",
          icon: "tim-icons icon-gift-2"
        },
        { name: "Sessions", icon: "tim-icons icon-tap-02" }
      ];
    }
  },
  watch: {
    blockedList: function(val) {
      // console.log(val)
    },
    disabledRooms: function(val) {
      // console.log(val)
    },
    date: function(val) {
      // Set today as default date if input is cleared
      // console.log(val)
      if (!val) {
        this.date = [
          moment()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toDate(),
          moment()
            .add(1, "days")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toDate()
        ];
      }
      this.loadRoomData();
      this.loadRoomStatus();
    }
  },
  mounted() {
    auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
        this.loadRooms()
          .then(() => {
            this.loadRoomStatus().then(() => {
              this.loadRoomData();
            });
          })
          .finally(() => {
            this.$root.loading = false;
          });
      }
    });
    // this.initBigChart(0);
  },
  created() {
    moment().utcOffset("-06:00");
    Moment().utcOffset("-6:00");
  },
  beforeDestroy() {
    //unsubscribe firestore snapshots
    this.$root.snapshots.rooms();
    this.$root.snapshots.availability();
    this.$root.snapshots.blocked();
  },
  methods: {
    reserve() {
      this.$router.push({
        name: "RegisterJobs",
        params: {
          checkinDate: this.date[0].toString(),
          checkoutDate: this.date[1].toString(),
          roomNumber: this.tempRoomData
        }
      });
    },
    diffDate(date1, date2) {
      if (!!date1 && !!date2) {
        const diffTime = Math.abs(date2 - date1);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }
    },
    parseDate(date) {
      try {
        return moment(date.seconds * 1000).format("DD/MM/yyyy");
      } catch (e) {
        this.$h.showError(null, e);
      }
    },
    roomStatus(occupied, blocked, blockedList, room) {
      // console.log(room, occupied, blocked )
      if (occupied) {
        return "bg-theme-primary";
      } else if (blocked) {
        return "bg-theme-info";
      } else {
        return "bg-theme-secondary";
      }
      // console.log(occupied, blocked)
    },
    async doBlockRoom() {
      let self = this;
      if (this.blocked.length) {
        if (
          !this.blocked.some(
            obj =>
              obj.room === this.tempRoomData &&
              moment
                .range(
                  new Date(obj.fromDate.seconds * 1000),
                  new Date(obj.toDate.seconds * 1000)
                )
                .overlaps(moment.range(this.date[0], this.date[1]))
          )
        ) {
          const { value: text } = await Swal.fire({
            input: "textarea",
            inputLabel: "Motivo",
            inputPlaceholder: "Motivo o detalles del bloqueo",
            inputAttributes: {
              "aria-label": "Escribe aquí"
            },
            showCancelButton: true
          });
          if (text) {
            this.$swal
              .fire({
                title: "Estas segur@?",
                text: `Estás a punto de bloquear esta habitación, deseas continuar?`,
                icon: "error",
                showCancelButton: true,
                customClass: {
                  confirmButton:
                    "button px-2 mr-1 mb-2 mt-2 bg-theme-secondary text-white",
                  cancelButton:
                    "button px-2 mr-1 mb-2 mt-2 bg-theme-danger text-white"
                },
                confirmButtonText: "Si, estoy segur@",
                buttonsStyling: true
              })
              .then(result => {
                if (result.value) {
                  this.blocked.push({
                    room: this.tempRoomData,
                    fromDate: this.date[0],
                    toDate: this.date[1],
                    reason: text
                  });
                  try {
                    db.collection("rooms_" + localStorage.getItem("hotel"))
                      .doc("status")
                      .update({
                        blockedRooms: this.blocked
                      });
                    this.$refs.actionRoom.close();
                    this.date.splice(0, this.date.length);
                    this.date = [
                      moment()
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .toDate(),
                      moment()
                        .add(1, "days")
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .toDate()
                    ];
                  } catch (e) {
                    this.$h.showError(null, e);
                  }
                }
              });
          } else {
            this.$swal({
              title: "Oups!",
              text: "Es necesario especificar el motivo del bloqueo",
              icon: "error",
              confirmButtonText: "Entiendo"
            });
          }
        } else {
          this.$swal({
            title: "Oups!",
            text:
              "Revisa que las fechas que vas a bloquear no se sobrepongan a un bloqueo ya existente",
            icon: "error",
            confirmButtonText: "Comprendo"
          });
          this.$refs.actionRoom.close();
          this.date.splice(0, this.date.length);
          this.date = [
            moment()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .toDate(),
            moment()
              .add(1, "days")
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .toDate()
          ];
        }
      } else {
        console.log("else in blocked");
        const { value: text } = await Swal.fire({
          input: "textarea",
          inputLabel: "Motivo",
          inputPlaceholder: "Motivo o detalles del bloqueo",
          inputAttributes: {
            "aria-label": "Escribe aquí"
          },
          showCancelButton: true
        });
        if (text) {
          this.$swal
            .fire({
              title: "Estas segur@?",
              text: `Estás a punto de bloquear esta habitación, deseas continuar?`,
              icon: "error",
              showCancelButton: true,
              customClass: {
                confirmButton:
                  "button px-2 mr-1 mb-2 mt-2 bg-theme-secondary text-white",
                cancelButton:
                  "button px-2 mr-1 mb-2 mt-2 bg-theme-danger text-white"
              },
              confirmButtonText: "Si, estoy segur@",
              buttonsStyling: true
            })
            .then(result => {
              if (result.value) {
                this.blocked.push({
                  room: this.tempRoomData,
                  fromDate: this.date[0],
                  toDate: this.date[1],
                  reason: text
                });
                try {
                  db.collection("rooms_" + localStorage.getItem("hotel"))
                    .doc("status")
                    .update({
                      blockedRooms: this.blocked
                    });
                  this.$refs.actionRoom.close();
                  this.date.splice(0, this.date.length);
                  this.date = [
                    moment()
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .toDate(),
                    moment()
                      .add(1, "days")
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .toDate()
                  ];
                } catch (e) {
                  this.$h.showError(null, e);
                }
              }
            });
        } else {
          this.$swal({
            title: "Oups!",
            text: "Es necesario especificar el motivo del bloqueo",
            icon: "error",
            confirmButtonText: "Entiendo"
          }).then(() => {
            this.$refs.actionRoom.close();
            this.date.splice(0, this.date.length);
            this.date = [
              moment()
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .toDate(),
              moment()
                .add(1, "days")
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .toDate()
            ];
          });
        }
      }
    },
    doUnBlockRoom(row) {
      let self = this;
      let index = this.blockedList.indexOf(this.tempRoomData);
      let index2 = this.blocked.findIndex(
        data =>
          data.room === this.tempRoomData &&
          data.fromDate === row.fromDate &&
          data.toDate === row.toDate
      );
      // console.log(index2)
      this.blockedList.splice(index, 1);
      this.blocked.splice(index2, 1);
      try {
        db.collection("rooms_" + localStorage.getItem("hotel"))
          .doc("status")
          .update({
            blockedRooms: this.blocked
          });
        this.$refs.actionRoom.close();
      } catch (e) {
        this.$h.showError(null, e);
      }
    },
    actionRoom(room, occupied, blocked) {
      this.tempRoomData = room;
      this.$refs.actionRoom.open();
    },
    addRoomNumber() {
      this.freeRooms++;
    },
    roomTypeClass(code) {
      switch (code) {
        case "RQ":
          return "card-animation-on-hover col-12 container-fluid";
        case "RB":
          return "card-animation-on-hover col-12 container-fluid";
        default:
          return "card-animation-on-hover col-5 container-fluid";
      }
    },
    async loadRooms() {
      //Load Main Rooms
      this.$root.snapshots.rooms = await db
        .collection("rooms_" + localStorage.getItem("hotel"))
        .orderBy("index")
        .onSnapshot(
          doc => {
            this.rooms.splice(0, this.rooms.length);
            doc.forEach(doc => {
              this.rooms.push(doc.data());
            });

            this.rooms.forEach(val => {
              if (val) {
                val.rooms.sort((a, b) =>
                  a.number > b.number ? 1 : b.number > a.number ? -1 : 0
                );
              }
            });
          },
          function(err) {
            console.log(err);
          }
        );
    },
    async loadRoomStatus() {
      let self = this;
      //blocked rooms
      this.$root.snapshots.blocked = await db
        .collection("rooms_" + localStorage.getItem("hotel"))
        .doc("status")
        .onSnapshot(
          function(doc) {
            self.blockedList.splice(0, self.blockedList.length);
            self.blocked.splice(0, self.blocked.length);
            doc.data().blockedRooms.forEach(val => {
              let checkinDate = new Date(val.fromDate.seconds * 1000);
              let checkoutDate = new Date(val.toDate.seconds * 1000);
              let roomNumber = val.room;
              let startDate = self.date[0];
              let endDate = self.date[1];

              let userRange = moment.range(checkinDate, checkoutDate);
              let selectedRange = moment.range(startDate, endDate);

              if (userRange.overlaps(selectedRange)) {
                self.blockedList[roomNumber] = true;
                self.blocked = doc.data().blockedRooms;
              }
            });
          },
          function(err) {
            console.log(err);
          }
        );
    },
    async loadRoomData() {
      //Load availability
      this.$root.snapshots.availability = db
        .collection(localStorage.getItem("hotel"))
        .doc("guest")
        .collection("reservations")
        .where("noShow", "==", false)
        .where("cancelled", "==", false)
        .orderBy("checkinDate")
        .onSnapshot(
          doc => {
            this.disabledRooms.splice(0, this.disabledRooms.length);
            this.tableData.splice(0, this.tableData.length);
            doc.forEach(doc => {
              let checkinDate = new Date(doc.data().checkinDate.seconds * 1000);
              let checkoutDate = new Date(
                doc.data().checkoutDate.seconds * 1000
              );
              let now = moment();
              let roomNumber = doc.data().roomNumber;
              let extraRooms = doc.data().extra_rooms;
              let startDate = this.date[0];
              let endDate = this.date[1];
              let userRange = moment.range(checkinDate, checkoutDate);
              let selectedRange = moment.range(startDate, endDate);
              if (userRange.overlaps(selectedRange)) {
                this.tableData.push(doc.data());
                // if (!doc.data().checkoutIsDone) {
                if (extraRooms !== undefined) {
                  extraRooms.forEach(room => {
                    this.disabledRooms[room] = true;
                  });
                }
                // console.log(roomNumber)
                this.disabledRooms[roomNumber] = true;
                // }
              }
            });
          },
          function(err) {
            console.log(err);
          }
        );
    },
    filterBlocked(data) {
      let result = data.filter(obj => {
        return (
          obj.room === this.tempRoomData &&
          moment
            .range(
              new Date(obj.fromDate.seconds * 1000),
              new Date(obj.toDate.seconds * 1000)
            )
            .overlaps(moment.range(this.date[0], this.date[1]))
        );
      });
      result.length
        ? (this.showBlockedTable = true)
        : (this.showBlockedTable = false);
      return result;
    },
    filterOccupied(data) {
      // console.log(data)
      let result = data.filter(obj => {
        return (
          (obj.roomNumber === this.tempRoomData &&
            moment
              .range(
                new Date(obj.checkinDate.seconds * 1000),
                new Date(obj.checkoutDate.seconds * 1000)
              )
              .overlaps(moment.range(this.date[0], this.date[1]))) ||
          (obj.extra_rooms.includes(this.tempRoomData) &&
            moment
              .range(
                new Date(obj.checkinDate.seconds * 1000),
                new Date(obj.checkoutDate.seconds * 1000)
              )
              .overlaps(moment.range(this.date[0], this.date[1])))
        );
      });
      result.length
        ? (this.showOccupiedTable = true)
        : (this.showOccupiedTable = false);
      return result;
    },
    cardFooterData(title) {
      switch (title) {
        case 1:
          return this.$root.dataBus.totalCheckins;
        case 2:
          return this.$root.dataBus.checkoutsLeft;
        case 3:
          return this.$root.dataBus.roomsInHouse;
        case 4:
          return this.$root.dataBus.paxInHouse;
        default:
        //
      }
    },
    getTotalsStrings(type) {
      let disabledRooms = 0;
      let blockedRooms = 0;
      this.disabledRooms.forEach(value => {
        disabledRooms++;
      });
      this.blockedList.forEach(value2 => {
        blockedRooms++;
      });
      switch (type) {
        case 1: //FreeRooms
          this.freeRooms = 41 - disabledRooms - blockedRooms;
          return this.freeRooms.toString();
        case 2:
          this.busyRooms = disabledRooms;
          return this.busyRooms.toString();
        case 3:
          this.blockedRooms = blockedRooms;
          return this.blockedRooms.toString();
        case 4:
          return this.dirtyRooms.toString();
        default:
        //
      }
      // console.log(this.$root.dataBus.totalCheckins)
      return this.$root.dataBus.totalCheckins.toString();
    }
  }
};
</script>
