const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "side-menu-dashboard",
        title: "Dashboard"
      },
      {
        icon: "BoxIcon",
        pageName: "side-menu-menu-layout",
        title: "Hotel",
        subMenu: [
          {
            icon: "",
            pageName: "side-menu-dashboard",
            title: "Habitaciones",
            ignore: true
          },
          {
            icon: "",
            pageName: "simple-menu-dashboard",
            title: "Ajustes",
            ignore: true
          }
        ]
      },
      {
        icon: "BoxIcon",
        pageName: "side-menu-menu-layout",
        title: "Huéspedes",
        subMenu: [
          {
            icon: "",
            pageName: "side-menu-dashboard",
            title: "Registrar",
            ignore: true
          },
          {
            icon: "",
            pageName: "simple-menu-dashboard",
            title: "Editar",
            ignore: true
          },
          {
            icon: "",
            pageName: "simple-menu-dashboard",
            title: "Credenciales",
            ignore: true
          }
        ]
      },
      {
        icon: "BoxIcon",
        pageName: "side-menu-menu-layout",
        title: "Configuración",
        subMenu: [
          {
            icon: "",
            pageName: "side-menu-dashboard",
            title: "Usuarios",
            ignore: true
          },
          {
            icon: "",
            pageName: "simple-menu-dashboard",
            title: "Tarifas",
            ignore: true
          }
        ]
      },
      {
        icon: "InboxIcon",
        pageName: "side-menu-inbox",
        title: "Inbox"
      },
      "devider"
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
