<template>
  <!-- BEGIN: Dark Mode Switcher-->
  <div
    class="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 right-0 box dark:bg-dark-2 border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 mr-10"
    @click="switchMode"
  >
    <div class="mr-4 text-gray-700 dark:text-gray-300">Dark Mode</div>
    <div
      :class="{ 'dark-mode-switcher__toggle--active': darkMode }"
      class="dark-mode-switcher__toggle border"
    ></div>
  </div>
  <!-- END: Dark Mode Switcher-->
</template>

<script>
export default {
  computed: {
    darkMode() {
      return this.$store.state.main.darkMode;
    }
  },
  mounted() {
    this.setDarkModeClass();
  },
  methods: {
    switchMode() {
      this.$store.dispatch("main/setDarkMode", !this.darkMode);
      this.setDarkModeClass();
    },
    setDarkModeClass() {
      this.darkMode
        ? cash("html").addClass("dark")
        : cash("html").removeClass("dark");
    }
  }
};
</script>
