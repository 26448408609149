<template>
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden">
    <!--    <div class="mobile-menu-bar">-->
    <!--      <BarChart2Icon-->
    <!--          class="flex mr-auto w-8 h-8 text-white transform -rotate-90"-->
    <!--          @click="toggleMobileMenu"-->
    <!--      />-->
    <!--      <i style="font-size: 26px" class="fa fa-sign-out-alt text-white" @click="logOutInit"></i>-->
    <!--    </div>-->
    <transition @enter="enter" @leave="leave">
      <ul
        v-if="this.$root.activeMobileMenu"
        class="border-t border-theme-24 py-5"
      >

        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <li
            v-if="menu == 'devider'"
            :key="menuKey"
            class="menu__devider my-6"
          ></li>
          <li v-else :key="menuKey">
            <a
              href="javascript:;"
              class="menu"
              :class="{
                'menu--active': menu.active,
                'menu--open': menu.activeDropdown
              }"
              @click="linkTo(menu)"
            >
              <div class="menu__icon">
                <component :is="menu.icon" />
              </div>
              <div class="menu__title">
                {{ menu.title }}
                <ChevronDownIcon
                  v-if="$h.isset(menu.subMenu)"
                  class="menu__sub-icon"
                  :class="{ 'transform rotate-180': menu.activeDropdown }"
                />
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <transition @enter="enter" @leave="leave">
              <ul v-if="$h.isset(menu.subMenu) && menu.activeDropdown">
                <li
                  v-for="(subMenu, subMenuKey) in menu.subMenu"
                  :key="subMenuKey"
                >
                  <a
                    href="javascript:;"
                    class="menu"
                    :class="{ 'menu--active': subMenu.active }"
                    @click="linkTo(subMenu)"
                  >
                    <div class="menu__icon">
                      <ActivityIcon />
                    </div>
                    <div class="menu__title">
                      {{ subMenu.title }}
                      <ChevronDownIcon
                        v-if="$h.isset(subMenu.subMenu)"
                        class="menu__sub-icon"
                        :class="{
                          'transform rotate-180': subMenu.activeDropdown
                        }"
                      />
                    </div>
                  </a>
                  <!-- BEGIN: Third Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul
                      v-if="$h.isset(subMenu.subMenu) && subMenu.activeDropdown"
                    >
                      <li
                        v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                        :key="lastSubMenuKey"
                      >
                        <a
                          href="javascript:;"
                          class="menu"
                          :class="{ 'menu--active': lastSubMenu.active }"
                          @click="linkTo(lastSubMenu)"
                        >
                          <div class="menu__icon">
                            <ZapIcon />
                          </div>
                          <div class="menu__title">
                            {{ lastSubMenu.title }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Third Child -->
                </li>
              </ul>
            </transition>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </transition>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import Velocity from "velocity-animate";
import { auth } from "@/firebase/config";

export default {
  data() {
    return {
      formattedMenu: [],
      activeMobileMenu: this.$root.activeMobileMenu
    };
  },
  computed: {
    mobileMenu() {
      return this.nestedMenu(this.$store.state.TopMenu.menu);
    }
  },
  watch: {
    $route() {
      this.formattedMenu = this.$h.assign(this.mobileMenu);
    }
  },
  mounted() {
    this.formattedMenu = this.$h.assign(this.mobileMenu);
  },
  methods: {
    logOutInit() {
      this.$swal({
        icon: "question",
        title: "Salir del sistema",
        text: "Estás segur@ que deseas salir del sistema?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cerrar sesión",
        cancelButtonText: "Cancelar",
        showClass: {
          popup: "animate__animated animate__backInDown"
        },
        hideClass: {
          popup: "animate__animated animate__backOutDown"
        }
      }).then(result => {
        if (result.value) {
          this.signOut();
        }
      });
    },
    async signOut() {
      try {
        await auth.signOut().then(() => {
          localStorage.clear();
          sessionStorage.clear();
        });
      } catch (e) {
        await this.$h.showError(null, e);
      }
    },
    toggleMobileMenu() {
      this.$root.activeMobileMenu = !this.$root.activeMobileMenu;
    },
    nestedMenu(menu) {
      menu.forEach((item, key) => {
        if (typeof item !== "string") {
          menu[key].active =
            (item.pageName == this.$route.name ||
              (this.$h.isset(item.subMenu) &&
                this.findActiveMenu(item.subMenu))) &&
            !item.ignore;
        }

        if (this.$h.isset(item.subMenu)) {
          menu[key].activeDropdown = this.findActiveMenu(item.subMenu);
          menu[key] = {
            ...item,
            ...this.nestedMenu(item.subMenu)
          };
        }
      });

      return menu;
    },
    findActiveMenu(subMenu) {
      let match = false;
      subMenu.forEach(item => {
        if (item.pageName == this.$route.name && !item.ignore) {
          match = true;
        } else if (this.$h.isset(item.subMenu)) {
          match = this.findActiveMenu(item.subMenu);
        }
      });
      return match;
    },
    linkTo(menu) {
      if (this.$h.isset(menu.subMenu)) {
        menu.activeDropdown = !menu.activeDropdown;
      } else {
        this.$root.activeMobileMenu = false;
        this.$router.push({
          name: menu.pageName
        });
      }
    },
    enter(el, done) {
      Velocity(
        el,
        "slideDown",
        {
          duration: 300
        },
        {
          complete: done
        }
      );
    },
    leave(el, done) {
      Velocity(
        el,
        "slideUp",
        {
          duration: 300
        },
        {
          complete: done
        }
      );
    }
  }
};
</script>
