//Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBZPuaJ8xLGCsJxmLMq8PNJBHg9zOlms6U",
  authDomain: "rapicr.firebaseapp.com",
  databaseURL: "https://rapicr.firebaseio.com",
  projectId: "rapicr",
  storageBucket: "rapicr.appspot.com",
  messagingSenderId: "565916761566",
  appId: "1:565916761566:web:35cc60b9693d9bd6c0714f",
  measurementId: "G-LVV56QXCSB"
};

//initialize Firebase
firebase.initializeApp(firebaseConfig);

let fireAdmin = firebase.initializeApp(firebaseConfig, "ADMIN");

// The default cache size threshold is 40 MB. Configure "cacheSizeBytes"
// for a different threshold (minimum 1 MB) or set to "CACHE_SIZE_UNLIMITED"
// to disable clean-up.
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase
  .firestore()
  .enablePersistence()
  .catch(function(err) {
    if (err.code === "failed-precondition") {
      console.log(
        "Failed-precondition, firebase persistence can only be enabled in one tab at a time"
      );
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      console.log("unimplemented");
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(function() {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
    // return firebase.auth().signInWithEmailAndPassword(email, password);
  })
  .catch(function(error) {
    // Handle Errors here.
    console.log(error);
    // var errorCode = error.code;
    // var errorMessage = error.message;
  });

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const adminDB = fireAdmin.firestore();
export const adminAuth = fireAdmin.auth();
export const adminStorage = fireAdmin.storage();

export default {
  db,
  auth,
  storage,
  adminDB,
  adminAuth,
  adminStorage
};
